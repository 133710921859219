import axios from "axios";
import {
  ENQUERY_REQUEST,
  ENQUERY_SUCCESS,
  ENQUERY_FAILURE,
} from "./enqueryTypes";

export const enqueryRequest = () => {
  return {
    type: ENQUERY_REQUEST,
  };
};

export const enquerySuccess = (dataEnquery) => {
  return {
    type: ENQUERY_SUCCESS,
    playload: dataEnquery,
  };
};

export const enqueryFailure = (error) => {
  return {
    type: ENQUERY_FAILURE,
    playload: error,
  };
};
export const enqueryAPI = ({ name, phone, email, country, enquery }) => {
  const options = {
    headers: { "Content-Type": "application/json" },
  };

  return (dispatch) => {
    dispatch(enqueryRequest());
    axios
      .post(
        "https://backend.digitalghumti.com/api/enquiry",
        {
          name: name,
          phone: phone,
          email: email,
          country: country,
          enquiry: enquery,
        },
        options
      )
      .then((response) => {
        const dataEnquery = response.data;
        dispatch(enquerySuccess(dataEnquery));
      })
      .catch((error) => {
        const errorMessage = error?.message;
        // console.log("error", errorMessage);
        dispatch(enqueryFailure(errorMessage));
      });
  };
};
