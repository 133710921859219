import React, { useEffect } from "react";
import ExpRoadmapCard from "./ExpRoadmapCard";

import { connect } from "react-redux";

import { fetchRoadmapList } from "../Actions/index.jsx";

import Loader from "../Components/Loader";

const ExpertiseDetails = ({ fetchRoadmapList, roadmapData }) => {
  useEffect(() => {
    fetchRoadmapList();
  }, [fetchRoadmapList]);
  return (
    <aside className="aside--right">
      {roadmapData.loading ? (
        <Loader />
      ) : roadmapData.error ? (
        <>
          <Loader />
          <h1>Network Error</h1>
        </>
      ) : (
        roadmapData &&
        roadmapData.roadmap &&
        roadmapData.roadmap.map((item, index) => {
          return <ExpRoadmapCard {...item} index={index} key={index} />;
        })
      )}
    </aside>
  );
};

const mapStateToProps = (state) => {
  return {
    roadmapData: state.RoadmapData,
  };
};

export default connect(mapStateToProps, { fetchRoadmapList })(ExpertiseDetails);
