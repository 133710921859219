import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ContactHeader from "../PageCompontents/ContactHeader";
// all for select
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
// for select end
// input
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { projectInitiateApi } from "../Actions/projectInitiateAPI";

// fetching part
import { connect } from "react-redux";
// import { Location } from "../Actions/Location";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { ReactComponent as DeleteIcon } from "../svg/delete.svg";

const currency = ["NPR", "US Dollor", "INR"];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SvgArrow = ({ color }) => {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4849 1.00023L6.22408 6.23193L1 1.00023"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

const Contact = ({ data, projectInitiateApi }) => {
  // setting up dataSingle
  const [currencyIndex, setCurrencyIndex] = useState(0);

  const [sendingData, setData] = useState({
    service: "",
    client: "",
    phone: "",
    email: "",
    company: "",
    country: "",
    detail: "",
    link: "",
    budget_type: "",
    budget: "",
    file: [],
  });
  const { client, phone, email, company, country, detail, file } = setData;

  let location = "Nepal";

  // select
  const [currencyValue, setCurrencyValue] = React.useState("NPR");
  const selectHandleChange = (event) => {
    setCurrencyValue(event.target.value);
    setCurrencyIndex(event.currentTarget.getAttribute("index"));
    setData({
      ...sendingData,
      budget_type: event.target.value,
    });
  };
  let { state } = useLocation();

  // list of service
  const serviceList = [
    {
      name: "Branding",
    },
    {
      name: "Graphic Design",
    },
    {
      name: "Animaiton",
    },
    {
      name: "Digital Marketing",
    },
    {
      name: "Website & App ",
    },
    {
      name: "Other",
    },
  ];

  // list of service
  const budgetList = [
    [
      {
        name: "5-15k",
      },
      {
        name: "15-25k",
      },
      {
        name: "25-55k",
      },
      {
        name: "55-95k",
      },
      {
        name: "more than 100k",
      },
      {
        name: "I don’t know yet",
      },
    ],
    [
      {
        name: "100-500",
      },
      {
        name: "1-2k",
      },
      {
        name: "2-3k",
      },
      {
        name: "3-4k",
      },
      {
        name: "more than 5k",
      },
      {
        name: "I don’t know yet",
      },
    ],
    [
      {
        name: "5-10k",
      },
      {
        name: "10-20k",
      },
      {
        name: "20-40k",
      },
      {
        name: "40-60k",
      },
      {
        name: "more than 60k",
      },
      {
        name: "I don’t know yet",
      },
    ],
  ];

  // initiallizing
  useEffect(() => {
    document.querySelector("body").classList.remove("overflow");
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    switch (location) {
      case "Nepal":
        setCurrencyValue("NPR");
        setCurrencyIndex(0);
        break;
      case "India":
        setCurrencyValue("INR");
        setCurrencyIndex(2);
        break;

      default:
        setCurrencyValue("US Dollor");
        setCurrencyIndex(1);
    }
    setData({
      ...sendingData,
      budget_type: currencyValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // lets work on some form submit

  let history = useHistory();

  // checking if send button clicked
  const [sendBtn, setSendBtn] = useState(false);

  const Submit = (e) => {
    e.preventDefault();
    setSendBtn(true);
    // console.log("form submit");
    if (localStorage.getItem("projName")) {
      localStorage.removeItem("projName");
    }

    if (sendingData.client) {
      localStorage.setItem("projName", sendingData.client);
    }

    // console.log("before submit", sendingData);
    projectInitiateApi(sendingData);
    // history.push("/Submit/yam");
  };

  // onchange event for all input
  const onChange = (event) => {
    console.log("change");
    if (event.currentTarget.name === "file") {
      setData({
        ...sendingData,
        [event.currentTarget.name]: event.target.files[0],
      });
    } else {
      setData({
        ...sendingData,
        [event.currentTarget.name]: event.currentTarget.value,
      });
    }
  };
  // const clearFile = (e) => {
  //   setData({
  //     ...sendingData,
  //     file: null,
  //   });
  // };
  useEffect(() => {
    console.log("data", sendingData);
  }, [sendingData]);

  // after submit if its submitted
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    if (data?.loading === false) {
      if (data?.data?.status === true) {
        // console.log("data sent sucessfully");
        setSendBtn(false);
        setErrorMessage(false);
        history.push(
          "/Submit/" + (sendingData.client || localStorage.getItem("projName"))
        );
      } else if (data.error) {
        setErrorMessage(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // scroll
  const scroll = useRef(null);
  useEffect(() => {
    if (state?.scroll && scroll?.current) {
      setTimeout(() => {
        scroll?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
    }
  }, [state]);

  return (
    <>
      <Helmet>
        <title>Digital Ghumti - Start a Project</title>
      </Helmet>
      <main className="other-page projects-page contact--page">
        <section className="project--template ">
          <div className="section__rule">
            <div className="d-flex">
              <aside className="aside--left">
                <div className="aside--left__top">
                  <h2 className="section__title">
                    Come <span className="text--secondary">closer</span> <br />
                    <span>to your dreams</span>
                  </h2>
                  <div className="section__title--lg__wrapper">
                    <h2 className="section__title section__title--lg">
                      Contact
                    </h2>
                  </div>
                </div>
              </aside>

              <aside className="aside--right">
                <ContactHeader active={1} />
                <div className="swipeable-container">
                  <h2 className="section__title text--secondary " ref={scroll}>
                    Tell us about your
                    <br />
                    next project.
                  </h2>

                  <form method="post" action="#!" onSubmit={(e) => Submit(e)}>
                    <h3 className="title--small card__margin">
                      What services do you need?
                    </h3>
                    <article className="form-group ">
                      <div className="list-group">
                        {serviceList.map((service, index) => {
                          return (
                            <div className="form-check" key={index}>
                              <input
                                name="service"
                                className="form-check-input"
                                type="radio"
                                value={service.name}
                                id={service.name + "-" + index}
                                onChange={onChange}
                              />
                              <label
                                className="list-group-item list-group-item-action form-check-label noselect"
                                htmlFor={service.name + "-" + index}
                              >
                                {service.name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </article>

                    <div className="title--wrapper card__margin ">
                      <h2 className="title--small mb-0">Budget in </h2>
                      <Select
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"
                        value={currencyValue}
                        onChange={selectHandleChange}
                        input={<Input />}
                        MenuProps={MenuProps}
                        IconComponent={() => <SvgArrow />}
                        name="budget_type"
                      >
                        {currency.map((name, index) => (
                          <MenuItem key={index} value={name} index={index}>
                            <h3 className="title--small mb-0" index={name}>
                              {" "}
                              {name}
                            </h3>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    <article className="form-group budget">
                      <div className="list-group">
                        {budgetList[currencyIndex].map((budget, index) => {
                          return (
                            <div className="form-check" key={index}>
                              <input
                                name="budget"
                                className="form-check-input"
                                type="radio"
                                value={budget.name}
                                id={budget.name + "-" + index}
                                onChange={onChange}
                              />
                              <label
                                className="list-group-item list-group-item-action form-check-label noselect"
                                htmlFor={budget.name + "-" + index}
                              >
                                {budget.name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </article>

                    <article className="form-group text-input">
                      <div className="row ">
                        <div className="col-md-6">
                          <div className="title--small card__margin">
                            <TextField
                              name="client"
                              label="Hi! I am"
                              required={true}
                              type="text"
                              value={client}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="title--small card__margin">
                            <TextField
                              name="phone"
                              label="Phone"
                              required={true}
                              type="tel"
                              value={phone}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="title--small card__margin">
                            <TextField
                              name="email"
                              label="Email"
                              required={true}
                              type="email"
                              value={email}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="title--small card__margin">
                            <TextField
                              name="company"
                              label="Company Name (optional)"
                              required={false}
                              type="text"
                              value={company}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="title--small card__margin">
                            <TextField
                              name="country"
                              label="Country"
                              required={true}
                              type="text"
                              defaultValue={location}
                              value={country}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb">
                            <div
                              className={
                                "title--small card__margin file-upload--wrapper " +
                                (sendingData?.file?.name && " active ")
                              }
                            >
                              <div className={" file-upload "}>
                                <input
                                  name="file"
                                  required={false}
                                  type="file"
                                  value={file}
                                  onChange={onChange}
                                  accept="application/pdf,application/vnd.ms-excel,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain"
                                  id="projectFile"
                                />
                                <label
                                  className="fakelabel"
                                  htmlFor="projectFile"
                                >
                                  <svg
                                    width="22"
                                    height="45"
                                    viewBox="0 0 22 45"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M15.6429 17.3418V33.7749C15.6429 36.4225 13.5893 38.5223 11 38.5223C8.41071 38.5223 6.35714 36.4225 6.35714 33.7749V8.4862C6.35714 4.37792 9.66071 1 13.6786 1C17.6964 1 21 4.37792 21 8.4862V33.7749C21 39.4352 16.5357 44 11 44C5.46429 44 1 39.4352 1 33.7749V17.3418"
                                      stroke="#BDBDBD"
                                      strokeWidth="2"
                                      strokeMiterlimit="10"
                                    />
                                  </svg>

                                  <span>
                                    {/* fileName ? fileName : */}
                                    {sendingData?.file?.name
                                      ? sendingData?.file?.name
                                      : "Attachment"}
                                  </span>
                                </label>
                                {/* {sendingData?.file?.name && (
                                  <DeleteIcon
                                    className="delete--label"
                                    onClick={clearFile}
                                  />
                                )} */}
                              </div>
                            </div>

                            {sendingData?.file?.size / 1024 / 1024 > 4 ? (
                              <small className="card__margin text-danger">
                                The total size of attachments should not exceed
                                5MB.
                              </small>
                            ) : (
                              <small className="card__margin text-muted">
                                Not more than 5MB
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="title--small card__margin">
                            <TextField
                              name="detail"
                              label="Project Details or Link (optional)"
                              required={false}
                              // type="text"
                              value={detail}
                              onChange={onChange}
                              multiline
                              maxrows={4}
                            />
                          </div>
                        </div>
                      </div>
                    </article>
                    {errorMessage && (
                      <p className="text-danger">
                        Error ! . check your filled twice
                      </p>
                    )}
                    <button type="submit" className="outline loader--btn">
                      {sendBtn && !errorMessage && <CircularProgress />}{" "}
                      <span>Submit Form</span>
                    </button>
                  </form>
                </div>
              </aside>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // LocationData: state.Location,
    data: state.ProjectInitiateReducers,
  };
};

export default connect(mapStateToProps, { projectInitiateApi })(Contact);
