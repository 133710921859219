import {
  PROJECT_REQUEST,
  PROJECT_SUCCESS,
  PROJECT_FAILURE,
} from "../Actions/projectTypes";

const initialState = {
  loading: true,
  data: [],
  error: "",
};

const projectAPI = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROJECT_SUCCESS:
      return {
        loading: false,
        data: action.playload,
        error: "",
      };
    case PROJECT_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.playload,
      };
    default:
      return state;
  }
};

export default projectAPI;
