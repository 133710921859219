import React, { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";

import store from "./store";
import { Provider } from "react-redux";

import { BrowserRouter as Router } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import VisibilitySensor from "react-visibility-sensor";

import Header from "./Components/Header";
import Navigation from "./Components/Navigation";
import Footer from "./Components/Footer";
import ModalSwitch from "./ModalSwitch";
import PreLoader from "./Components/PreLoader";

function App() {
  const [scrollTop, setScrollTop] = useState(0);

  const [last_known_scroll_position, setLast] = useState(0);

  useEffect(() => {
    let ticking = false;

    window.addEventListener("scroll", (e) => {
      setLast(window.scrollY);

      if (!ticking) {
        window.requestAnimationFrame(function () {
          setScrollTop(Math.floor(last_known_scroll_position));
          ticking = false;
        });
        ticking = true;
      }
    });
  }, [last_known_scroll_position, scrollTop]);

  return (
    <Provider store={store}>
      <HelmetProvider>
        <div className="App ">
          <Helmet>
            <title>Digital Ghumti</title>
          </Helmet>
          {/* <div id="fb-root"></div>
          <div
            className="fb-customerchat"
            attribution="biz_inbox"
            page_id="107978187301260"
            // app_id="382222976916209"
          ></div> */}

          <Router>
            <Header />
            <Navigation />
            <ModalSwitch scrollTop={scrollTop} />
            <Footer />
            <PreLoader />
          </Router>
        </div>
      </HelmetProvider>
    </Provider>
  );
}

export default App;
