import React from "react";

import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Submit = () => {
  let { id } = useParams();

  return (
    <>
      <Helmet>
        <title>Digital Ghumti - Thanks {id}</title>
      </Helmet>
      <main className="page-404 page-enquery page-submit">
        <section className="screen screen--template">
          <div className="section__rule  g-0">
            <div className="row no-gutter align-items-center">
              <aside className="col">
                <div className="image">
                  <div className="wrapper--404">
                    <svg
                      width="156"
                      height="156"
                      viewBox="0 0 156 156"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0)">
                        <path
                          d="M77.9641 0C34.9297 0 0 34.9457 0 78C0 99.9577 9.10897 119.834 23.7407 134.042C37.7986 147.676 56.949 156.072 78.0359 156.072C99.1228 156.072 118.273 147.676 132.259 134.042C146.891 119.834 156 100.029 156 78C156 34.9457 121.07 0 77.9641 0ZM43.9669 78C43.9669 59.1996 59.1724 43.9871 77.9641 43.9871C96.7559 43.9871 111.961 59.1996 111.961 78C111.961 96.8004 96.7559 112.013 77.9641 112.013C59.1724 112.013 43.9669 96.8004 43.9669 78Z"
                          fill="#03256C"
                        />
                        <path
                          d="M77.9641 112.013C56.8772 112.013 37.7269 120.408 23.6689 134.042C37.7269 147.676 56.8772 156.072 77.9641 156.072C99.051 156.072 118.201 147.676 132.188 134.042C118.201 120.408 99.051 112.013 77.9641 112.013Z"
                          fill="#00AAE7"
                          className="body"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="156" height="156" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </aside>
              <aside className="col aside--right px-0">
                <div className="screen__inner">
                  <article>
                    <h1 className="section__title">Thanks, {id}!</h1>
                    <p>
                      We have received the enquiry and would love to discuss the
                      project in lenght. We generally reply within 24hours!
                    </p>

                    <Link to="/projects" className="outline">
                      Back to Project
                    </Link>
                  </article>
                </div>
              </aside>
            </div>
          </div>
        </section>
        <img
          src="https://res.cloudinary.com/dem2xvk2e/image/upload/v1625666381/samples/enquerybg_pekonw.png"
          alt="Digital Ghumti - See our projects"
          className="fixed--image"
        />
      </main>
      <footer>
        <div className="section__rule">
          <div className="footer--bottom">
            <p className="para copyright">© 2021 Digital Ghumti Pvt.Ltd.</p>

            <address className="aside--right">
              <p className="para ">Or email us at</p>
              <a href="mailto:info@digitalghumti.com" className="para ">
                <b>info@digitalghumti.com</b>
              </a>
            </address>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Submit;
