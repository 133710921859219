import React, { useState, useRef } from "react";

import RoadmapList from "./RoadmapList";
// import { ReactComponent as Arrow } from "../svg/long--left.svg";

const Roadmap = (props) => {
  let { roadmap } = props;

  const [dart, setDart] = useState(false);

  // transform rect rotate
  const rect = useRef(null);
  const circle = useRef(null);

  let last_known_scroll_position = 0;
  let ticking = false;

  const roadmapScroll = (e) => {
    let el = e.target;
    last_known_scroll_position = el.scrollTop;
    // know how much element is scrolling
    let elemHeight = el.scrollHeight - el.offsetHeight;

    // strock calculate
    let elemScroll = Math.floor(
      (last_known_scroll_position * 760) / elemHeight + 410
    );

    // rotate to somewhat deg
    let elemDeg = Math.floor((last_known_scroll_position * 260) / elemHeight);

    setDart(elemScroll > 1000);

    if (!ticking) {
      window.requestAnimationFrame(function () {
        circle.current.style.strokeDasharray = `${elemScroll}px, 1080px`;
        rect.current.style.transform = `translate(4px, 250px) rotate(${elemDeg}deg)`;
        ticking = false;
      });
      ticking = true;
    }
  };

  return (
    <section
      className="roadmap bg--primary"
      data-anchor="page 2"
      // eslint-disable-next-line react/jsx-no-duplicate-props
      ref={roadmap}
      id="roadmap"
    >
      <div className="vh" onScroll={roadmapScroll}>
        <div className="d-flex section__rule">
          <aside className="aside__left">
            <svg
              width="365"
              height="386"
              viewBox="0 0 365 386"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="185"
                cy="187"
                r="150"
                stroke="#00AAE7"
                strokeWidth="45"
                className="dg"
                ref={circle}
              />

              <circle
                cx="185"
                cy="187"
                r="59.4595"
                stroke="white"
                strokeWidth="45"
              />
              <rect
                width="100"
                height="130"
                className={"rect " + (dart && " active ")}
                ref={rect}
              />
              <rect
                width="100"
                height="100"
                className={"rect last " + (dart && " active ")}
              />
            </svg>
            <img
              src="https://res.cloudinary.com/dem2xvk2e/image/upload/v1616514820/Roadmap/dart_hapnok.png"
              alt="dart"
              className={"dart " + (dart && " active ")}
            />
          </aside>
          <RoadmapList />
          {/* <Link to="expertise" className="mobile--only mobile--link">
            <Arrow />
          </Link> */}
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
