import axios from "axios";
import {
  PJINITIATE_REQUEST,
  PJINITIATE_SUCCESS,
  PJINITIATE_FAILURE,
} from "./projectInitiateTypes";

export const projectInRequest = () => {
  return {
    type: PJINITIATE_REQUEST,
  };
};

export const projectInSuccess = (dataEnquery) => {
  return {
    type: PJINITIATE_SUCCESS,
    playload: dataEnquery,
  };
};

export const projectInFailure = (error) => {
  return {
    type: PJINITIATE_FAILURE,
    playload: error,
  };
};
export const projectInitiateApi = ({
  service,
  client,
  phone,
  email,
  company,
  budget_type,
  budget,
  country,
  detail,
  file,
}) => {
  const options = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  return (dispatch) => {
    dispatch(projectInRequest());

    let formData = new FormData();
    formData.append("service", service);
    formData.append("client", client);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("company", company);
    formData.append("country", country);
    formData.append("detail", detail);
    formData.append("link", " ");
    formData.append("budget_type", budget_type);
    formData.append("budget", budget);
    formData.append("file", file);
    // console.table([
    //   service,
    //   client,
    //   phone,
    //   email,
    //   company,
    //   budget_type,
    //   budget,
    //   country,
    //   detail,
    //   file,
    // ]);
    axios
      .post(
        "https://backend.digitalghumti.com/api/project-initate",
        formData,
        options
      )
      .then((response) => {
        const data = response.data;
        console.log("done");
        dispatch(projectInSuccess(data));
      })
      .catch((error) => {
        error = error?.message;
        console.log("error", error);
        dispatch(projectInFailure(error));
      });
  };
};
