import axios from "axios";
import { TESTI_REQUEST, TESTI_SUCCESS, TESTI_FAILURE } from "./testiTypes";

export const testiRequest = () => {
  return {
    type: TESTI_REQUEST,
  };
};

export const testiSuccess = (data) => {
  return {
    type: TESTI_SUCCESS,
    playload: data,
  };
};

export const testiFailure = (error) => {
  return {
    type: TESTI_FAILURE,
    playload: error,
  };
};
// backend.digitalghumti.com/api/testi/by-type
export const testiAPI = () => {
  return (dispatch) => {
    dispatch(testiRequest());
    axios
      .get(process.env.REACT_APP_API + "testimonial")
      .then((response) => {
        const data = response.data;
        dispatch(testiSuccess(data));
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.log(errorMessage);
        dispatch(testiFailure(errorMessage));
      });
  };
};
