import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
const PreLoader = () => {
  // preloader
  const [loader, loaderToggle] = useState(false);
  const [open, toggle] = useState(false);

  let location = useLocation();

  useEffect(() => {
    let header = document.querySelector("header");
    let footer = document.querySelector("footer");
    let main = document.querySelector("main");
    let modal = document.querySelector(".modal--projects");
    let hamburger = document.querySelector(".hamburger");

    // if (!location.state) {
    if (header && footer && main) {
      header.classList.add("opacity");
      footer.classList.add("opacity");
      main.classList.add("opacity");
      hamburger.classList.add("opacity");
    }
    if (modal) {
      modal?.classList.add("opacity");
    }
    toggle(true);
    setTimeout(() => {
      loaderToggle(true);
      toggle(false);
    }, 1300);
    setTimeout(() => {
      // loaderToggle(true);
      // toggle(false);
      if (header && footer && main) {
        header.classList.remove("opacity");
        main.classList.remove("opacity");
        footer.classList.remove("opacity");
        hamburger.classList.remove("opacity");
      }
      if (modal) {
        modal?.classList.remove("opacity");
      }
    }, 2000);
    setTimeout(() => {
      loaderToggle(false);
    }, 4000);
    // }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <div className={"preloader " + (loader ? " active " : " ")}></div>
      <div className={"preloader bottom" + (open ? " active " : " ")}></div>
    </>
  );
};

export default PreLoader;
