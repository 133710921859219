import React, { useEffect } from "react";

import ClientsCard from "./ClientsCard";

// import person1 from "../images/Person/sagar_kharel.webp";
// import person2 from "../images/Person/Himal_Kandel.webp";
// import person3 from "../images/Person/Nabaraj_Poudel.webp";
// import person4 from "../images/Person/Manabi_shrestha.webp";
// import person5 from "../images/Person/Ramchandra_Paudel.webp";
// import person6 from "../images/Person/Saroj_koirala.webp";

import Slider from "react-slick";

// fetching part
import { connect } from "react-redux";
import { testiAPI } from "../Actions/testiAPI";
import { Skeleton } from "@material-ui/lab";

const Clients = ({ testiAPI, data }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    pauseOnHover: false,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 840,
        settings: {
          autoplay: false,
        },
      },
    ],
  };

  useEffect(() => {
    testiAPI();
  }, [testiAPI]);

  return (
    <section className="clients">
      <div className="section__rule vh">
        <h2 className="section__title section__title--lg">Clients</h2>
        <div className="d-flex">
          <div className="carousel--wrapper">
            {data?.error !== "" ? (
              <span>something went wrong !</span>
            ) : data?.loading ? (
              <div className="testi--skeleton">
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={300}
                  className="rect"
                />
                <div className="d-flex">
                  <Skeleton variant="circular" className="circle" />
                  <div>
                    <Skeleton variant="text" className="text" />
                    <Skeleton variant="text" className="text" />
                  </div>
                </div>
              </div>
            ) : (
              <Slider {...settings}>
                {data?.data?.data?.map((client, index) => {
                  return <ClientsCard {...client} index={index} key={index} />;
                })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.testiReducer,
  };
};

export default connect(mapStateToProps, { testiAPI })(Clients);
