import {
  TESTI_REQUEST,
  TESTI_SUCCESS,
  TESTI_FAILURE,
} from "../Actions/testiTypes";

const initialState = {
  loading: true,
  data: [],
  error: "",
};

const testiReducer = (state = initialState, action) => {
  switch (action.type) {
    case TESTI_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TESTI_SUCCESS:
      return {
        loading: false,
        data: action.playload,
        error: "",
      };
    case TESTI_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.playload,
      };
    default:
      return state;
  }
};

export default testiReducer;
