import React, { useState, useEffect, useRef } from "react";

import Slider from "react-slick";
import { Link } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ModalSlick from "./ModalSick";

import SampleNextArrow from "../SampleArrow/SampleNextArrow";
import SamplePrevArrow from "../SampleArrow/SamplePrevArrow";

import AsideRight from "./AsideRight";

import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { Helmet } from "react-helmet-async";

const ModalMain = (props) => {
  const {
    // reverseToggle,
    client,
    projectType,
    role,
    location,
    description,
    images,
    title,
    image50,
  } = props;
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const copyRef = useRef(null);
  const parser = new DOMParser();
  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    asNavFor: ".slider-nav",
    infinite: true,
    easing: "ease-in",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const settingsThumbs = {
    slidesToShow: images.length <= 5 ? images.length : 5,
    slidesToScroll: 0,
    asNavFor: ".slider-for",
    dots: false,
    centerMode: false,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "0px",
    draggable: false,
    infinite: true,
    arrows: false,
  };

  const url = useRef(null);

  const copy = (e) => {
    e.preventDefault();
    url.current.select();
    document.execCommand("copy");
    if (copyRef.current) {
      copyRef.current.classList.add("show");
      copyRef.current.classList.remove("hide");

      setTimeout(() => {
        copyRef.current.classList.remove("show");
        copyRef.current.classList.add("hide");
      }, 3000);
    }
  };

  const shareFb = (e) => {
    // eslint-disable-next-line no-undef
    FB.ui(
      {
        display: "popup",
        method: "share",
        href: window.location.href,
      },
      function (response) {
        console.log(response);
      }
    );
  };

  const shareNav = (e) => {
    e.preventDefault();
    // console.log("Thanks for sharing!");
    if (navigator.share) {
      navigator
        .share({
          title: title,
          text: parser.parseFromString(description, "text/html").activeElement
            .innerText,
          url: window.location,
        })
        .then(() => {
          // console.log("Thanks for sharing!");
        })
        .catch(console.error);
    }
  };

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          async
          defer
          src="//assets.pinterest.com/js/pinit.js"
        ></script>
        <script
          async
          defer
          crossorigin="anonymous"
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v11.0&appId=382222976916209&autoLogAppEvents=1"
          nonce="H5TXi88P"
        ></script>
      </Helmet>
      <div className="modal--main">
        <aside className="aside__left">
          <div className="slider-wrapper carousel--wrapper main--slider">
            <Slider
              {...settingsMain}
              asNavFor={nav2}
              ref={(slider) => setSlider1(slider)}
            >
              {images.map((slide, index) => (
                <ModalSlick
                  {...slide}
                  index={index}
                  key={index}
                  alt={"A Project of " + client + " by Digital Ghumti"}
                />
              ))}
            </Slider>
          </div>
          <div className="carousel--wrapper thumbnail-slider-wrap">
            <Slider
              {...settingsThumbs}
              asNavFor={nav1}
              ref={(slider) => setSlider2(slider)}
            >
              {images.map((slide, index) => (
                <ModalSlick {...slide} index={index} key={index} />
              ))}
            </Slider>
          </div>
          <article className="description--wrapper">
            <div
              className="para"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </article>
          <input
            type="text"
            name="url"
            value={window.location}
            ref={url}
            className="hide"
            readOnly
          />
        </aside>

        <AsideRight
          client={client}
          projectType={projectType}
          role={role}
          location={location}
        />

        <div className="button-outer">
          <Link to="/Projects">
            <button className="close">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 21L1 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1 21L21 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
              </svg>
            </button>
          </Link>
          <div className="share nav flex-column">
            <button
              className="active"
              onClick={(e) => {
                shareNav(e);
              }}
            >
              <svg
                width="28"
                height="22"
                viewBox="0 0 28 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.015 7.54478V1L26.2632 11L14.015 21V14.4552C9.12609 14.4552 4.62061 16.097 1 18.8582C1.95124 12.4552 7.41534 7.54478 14.015 7.54478Z"
                  stroke="#828282"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button className="active copy link" onClick={(e) => copy(e)}>
              <Tooltip
                title="Copy Link"
                aria-label="Copy Link"
                placement="left"
                arrow
                TransitionComponent={Zoom}
              >
                <svg
                  width="21"
                  height="25"
                  viewBox="0 0 21 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.615 10.6795C9.1146 9.81322 7.1679 10.3696 6.27237 11.9207L3.62312 16.5094C2.7276 18.0605 3.21907 20.0246 4.71949 20.8908L6.25116 21.7751C7.75158 22.6414 9.69828 22.085 10.5938 20.5339L11.5416 18.8923"
                    stroke="white"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M10.3557 14.6658C11.8561 15.532 13.8028 14.9756 14.6983 13.4245L17.3475 8.83588C18.2431 7.28479 17.7516 5.32068 16.2512 4.45442L14.7195 3.57011C13.2191 2.70384 11.2724 3.26026 10.3769 4.81135L9.4291 6.45292"
                    stroke="white"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                </svg>
              </Tooltip>
            </button>

            <button
              className="active facebook link"
              id="fb-share"
              onClick={(e) => {
                shareFb(e);
              }}
            >
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.3802 22.7605C5.10778 22.7605 0 17.6527 0 11.3802C0 5.10778 5.10778 0 11.3802 0C17.6527 0 22.7605 5.10778 22.7605 11.3802C22.7605 17.6527 17.6527 22.7605 11.3802 22.7605ZM11.3802 1.90305C6.15827 1.90305 1.90305 6.15827 1.90305 11.3802C1.90305 16.6022 6.15827 20.8574 11.3802 20.8574C16.6022 20.8574 20.8574 16.6022 20.8574 11.3802C20.8574 6.15827 16.6022 1.90305 11.3802 1.90305Z"
                  fill="#fff"
                ></path>
                <path
                  d="M10.2384 10.0099C9.71315 10.0099 9.28687 9.58366 9.28687 9.05842C9.28687 6.85849 11.0833 5.06201 13.2833 5.06201H13.9684C14.4936 5.06201 14.9199 5.48829 14.9199 6.01354C14.9199 6.53878 14.4936 6.96506 13.9684 6.96506H13.2833C12.1262 6.96506 11.1899 7.90136 11.1899 9.05842C11.1899 9.58366 10.7636 10.0099 10.2384 10.0099Z"
                  fill="#fff"
                ></path>
                <path
                  d="M10.2384 22.6843C9.71315 22.6843 9.28687 22.258 9.28687 21.7327V9.02037C9.28687 8.49513 9.71315 8.06885 10.2384 8.06885C10.7636 8.06885 11.1899 8.49513 11.1899 9.02037V21.7327C11.1899 22.258 10.7636 22.6843 10.2384 22.6843Z"
                  fill="#fff"
                ></path>
                <path
                  d="M13.9684 13.5876H7.72643C7.20119 13.5876 6.7749 13.1613 6.7749 12.6361C6.7749 12.1109 7.20119 11.6846 7.72643 11.6846H13.9684C14.4937 11.6846 14.92 12.1109 14.92 12.6361C14.92 13.1613 14.4937 13.5876 13.9684 13.5876Z"
                  fill="#fff"
                ></path>
              </svg>
            </button>

            <button className="active pintrest link">
              <a
                data-pin-do="buttonPin"
                data-pin-tall="true"
                data-pin-round="true"
                href="https://www.pinterest.com/pin/create/button/"
                target="_blank"
                rel="noreferrer"
                data-pin-description={
                  parser.parseFromString(description, "text/html").activeElement
                    .innerText
                }
                data-pin-media={image50}
                data-pin-url={window.location}
              >
                {/* window.location +
                  "%2F&media=" +
                  image50 +
                  "&description=" +
                  parser.parseFromString(description, "text/html").activeElement
                    .innerText +
                  "by digital ghumti" */}
                <img
                  src="//assets.pinterest.com/images/pidgets/pinit_fg_en_round_red_32.png"
                  alt="p"
                />
              </a>
            </button>
          </div>
        </div>

        <div className="position-fixed  end-0 p-3">
          <div
            id="liveToast"
            className="toast hide"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            ref={copyRef}
          >
            <div className="toast-body">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16Z"
                  stroke="#00AAE7"
                  strokeWidth="2"
                />
                <path
                  d="M12.6 19.6L8.4 15.4L7 16.8L12.6 22.4L24.6 10.4L23.2 9L12.6 19.6Z"
                  fill="#00AAE7"
                />
              </svg>
              <span>Linked Copied</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalMain;
