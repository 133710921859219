import React, { useEffect, useRef } from "react";
import Hotkeys from "react-hot-keys";
import ModalMain from "./components/ModalMain";
import BottomCard from "./components/BottomCard";
// import Loader from "../Components/Loader";
import { Skeleton } from "@material-ui/lab";

import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";
// import { useSpring, animated } from "react-spring";

// import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { singleAPI } from "../Actions/singleAPI";

// overflow body
// import { BodyOverflow } from "../Actions";

const ProjectsModal = ({ dataSingle, singleAPI }) => {
  let { id } = useParams();
  // const [open, toggle] = useState(false);
  const back = useRef(null);
  const prev = useRef(null);
  const next = useRef(null);
  const parser = new DOMParser();

  // const top = useSpring({
  //   reverse: open,
  //   // top: 0 + "px",
  //   // from: { top: 100 + "%" },
  //   opacity: 1,
  //   from: { opacity: 0 },
  //   config: { duration: 300 },
  // });

  // const dispatch = useDispatch();

  // const reverseToggle = () => {
  //   toggle(true);
  // };

  // useEffect(() => {
  //   toggle(false);
  //   // dispatch(BodyOverflow());
  // }, [toggle]);

  // fetching data only once
  useEffect(() => {
    singleAPI(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // keyup

  const keyup = (e) => {
    switch (e) {
      case "esc":
        back.current.click();
        break;
      case "right":
        if (next.current) next.current.click();
        break;
      case "left":
        if (prev.current) prev.current.click();
        break;
      default:
        return null;
    }
  };

  return (
    <>
      {dataSingle.data.data && (
        <Helmet>
          <title>Digital Ghumti - {dataSingle?.data?.data?.title}</title>
          <meta property="title" content={dataSingle?.data?.data?.title} />
          <meta
            name="description"
            content={
              parser.parseFromString(
                dataSingle?.data?.data?.description,
                "text/html"
              ).activeElement.innerText
            }
          />
          <meta property="type" content="article" />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={window.location} />
          <meta property="og:title" content={dataSingle?.data?.data?.title} />
          <meta
            property="og:description"
            content={
              parser.parseFromString(
                dataSingle?.data?.data?.description,
                "text/html"
              ).activeElement.innerText
            }
          />
          <meta property="og:image" content={dataSingle?.data?.data?.image50} />
          <meta property="image" content={dataSingle?.data?.data?.image50} />
        </Helmet>
      )}
      <Hotkeys keyName="esc, left, right" onKeyUp={(e) => keyup(e)}>
        {/* style={top} */}
        <section className="modal--projects">
          <div className="section__rule">
            {dataSingle?.error ? (
              <div>There is error in this page 😔😔</div>
            ) : dataSingle?.loading ? (
              <div className="loader--box">
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height={
                    window.innerHeight < 800 ? window.innerHeight - 300 : 500
                  }
                />
                <Skeleton variant="text" animation="wave" height={100} />
                <Skeleton variant="text" animation="wave" height={100} />
              </div>
            ) : (
              <>
                <ModalMain
                  // reverseToggle={reverseToggle}
                  client={dataSingle.data.data.client}
                  projectType={dataSingle.data.data.title}
                  role={dataSingle.data.data.role}
                  location={dataSingle.data.data.location}
                  description={dataSingle.data.data.description}
                  images={dataSingle.data.data.images}
                  title={dataSingle?.data?.data?.title}
                  image50={dataSingle.data.data.image50}
                />
                <div className="modal--bottom">
                  <div className="grid">
                    {dataSingle.data.data.prev && (
                      <BottomCard
                        data={dataSingle.data.data.prev}
                        key={1}
                        direction="prev"
                        ref={prev}
                      />
                    )}

                    {dataSingle.data.data.next && (
                      <BottomCard
                        data={dataSingle.data.data.next}
                        key={2}
                        direction="next"
                        ref={next}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <Link to="/Projects" className="modal__bg" ref={back}></Link>
        </section>
      </Hotkeys>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dataSingle: state.singleAPI,
  };
};

export default connect(mapStateToProps, { singleAPI })(ProjectsModal);
