import React from "react";

const Clients_Card = (props) => {
  const { image, index, alt } = props;

  return (
    <article className="item" key={index}>
      <div className="card__img" key={index}>
        <img src={image} alt={alt} title={alt} key={index} />
      </div>
    </article>
  );
};

export default Clients_Card;
