import axios from "axios";

import {
  FETCH_ROADMAP_REQUEST,
  FETCH_ROADMAP_SUCCESS,
  FETCH_ROADMAP_FAILURE,
} from "./roadmapTypes";

export const increment = () => {
  return {
    type: "INCREMENT",
  };
};

// fetch service
export const fetchRoadmapRequest = () => {
  return {
    type: FETCH_ROADMAP_REQUEST,
  };
};

const fetchRoadmapSuccess = (roadmap) => {
  return {
    type: FETCH_ROADMAP_SUCCESS,
    playload: roadmap,
  };
};

const fetchRoadmapFailure = (error) => {
  return {
    type: FETCH_ROADMAP_FAILURE,
    playload: error,
  };
};

export const fetchRoadmapList = () => {
  return (dispatch) => {
    dispatch(fetchRoadmapRequest);
    axios
      .get("https://anjitpariyar.github.io/demoJson/db.json")
      .then((response) => {
        const roadmap = response.data;
        dispatch(fetchRoadmapSuccess(roadmap));
      })
      .catch((error) => {
        const errorMessage = error.message;
        dispatch(fetchRoadmapFailure(errorMessage));
      });
  };
};

// https://anjitzero.github.io/demoJson/db.json

// bodyoverflow
export const BodyOverflow = () => {
  return {
    type: "OVERFLOWED",
  };
};

export const NoBodyOverflow = () => {
  return {
    type: "NOOVERFLOWED",
  };
};

export const HomePage = () => {
  return {
    type: "HOMEPAGE",
  };
};

export const OtherPage = () => {
  return {
    type: "OTHERPAGE",
  };
};
