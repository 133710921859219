import React, { useEffect, useState } from "react";
import ProjectsModal from "./modals/ProjectsModal";

import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HomePage, OtherPage } from "./Actions";
// import { NavigationMargin } from "./Reducers";

//pages
import Index from "./Pages";
import AboutUs from "./Pages/AboutUs";

import Projects from "./Pages/Projects";

import Expertise from "./Pages/Expertise";

import Contact from "./Pages/Contact";
import EnqueryForm from "./Pages/EnqueryForm";
import ProjectForm from "./Pages/ProjectForm";

import Blog from "./Pages/Blog";
import Submit from "./Pages/Submit";

import PageNotFound from "./Pages/404";

function ModalSwitch({ scrollTop }) {
  let location = useLocation();
  let background = location.state && location.state.background;

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(background);
    if (background) {
      document.querySelector("body").classList.add("overflow");
    } else {
      document.querySelector("body").classList.remove("overflow");
    }
  }, [background]);

  useEffect(() => {
    if (location.pathname === "/") {
      dispatch(HomePage());
    } else {
      dispatch(OtherPage());
    }
  }, [location, dispatch]);

  const [footerVb, setFooterVb] = useState(false);

  useEffect(() => {
    const footer = document.getElementById("footer");

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };
    let observer = new IntersectionObserver((entries, observer) => {
      setFooterVb(entries[0].isIntersecting);
    }, options);
    if (window.innerWidth > 840) {
      observer.observe(footer);
    }
  }, [location]);

  useEffect(() => {
    const footer = document.getElementById("footer");
    if (window.innerWidth > 840) {
      document.querySelector("header").classList.toggle("white", footerVb);
      document.querySelector(".hamburger").classList.toggle("white", footerVb);
      footer.classList.toggle("active", footerVb);
    }
  }, [footerVb, location]);

  return (
    <>
      <Switch location={background || location}>
        <Route exact path="/">
          <Index scrollTop={scrollTop} />
        </Route>
        <Route exact path="/about-us">
          <AboutUs />
        </Route>
        <Route exact path="/projects">
          <Projects />
        </Route>
        <Route exact path="/expertise">
          <Expertise scrollTop={scrollTop} />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/contact/enqueryform">
          <EnqueryForm />
        </Route>
        <Route exact path="/contact/projectform">
          <ProjectForm />
        </Route>
        <Route exact path="/blog/">
          <Blog />
        </Route>
        <Route exact path="/submit/:id">
          <Submit />
        </Route>
        <Route exact path="/projects/:id">
          <ProjectsModal />
        </Route>
        <Route exact path="/404">
          <PageNotFound />
        </Route>
        <Redirect to="/404" />
      </Switch>
      {background && (
        <Route path="/projects/:id" children={<ProjectsModal />} />
      )}
    </>
  );
}

export default ModalSwitch;
