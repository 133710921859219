import React from "react";

import { Helmet } from "react-helmet-async";
import ContactHeader from "../PageCompontents/ContactHeader";

// import "../css/component_css/template.scss";
// import "../css/otherstyle.scss";

// all for select

const Contact = () => {
  // initiallizing
  // useEffect(() => {
  //   document.querySelector("header").classList.remove("white");
  //   document.querySelector(".hamburger").classList.remove("white");

  //   document.querySelector("body").classList.remove("overflow");
  // });

  // const [rect, setRect] = useState(null);
  // const [isInViewport, setIsInViewport] = useState(false);

  // useEffect(() => {
  //   setRect(document.querySelector("footer").getBoundingClientRect());
  //   if (rect) setIsInViewport(rect.top <= window.innerHeight / 2);
  //   if (!isInViewport) {
  //     document.querySelector("header").classList.remove("white");
  //     document.querySelector(".hamburger").classList.remove("white");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [scrollTop]);

  // lets work on some form submit

  return (
    <>
      <Helmet>
        <title>Digital Ghumti - Contact Us</title>
      </Helmet>
      <main className="other-page projects-page contact--page">
        <section className="project--template ">
          <div className="section__rule">
            <div className="d-flex">
              <aside className="aside--left">
                <div className="aside--left__top">
                  <h2 className="section__title">
                    Come <span className="text--secondary">closer</span> <br />
                    <span>to your dreams</span>
                  </h2>
                  <h2 className="section__title section__title--lg">Contact</h2>
                </div>
              </aside>

              <aside className="aside--right">
                <ContactHeader active={0} />

                <div className="swipeable-container">
                  <h2 className="text-extreme-lg text-outline">Hello</h2>
                  <div className="contact__wrapper">
                    <article>
                      <p className="text-muted">Phone</p>
                      <p className="para">
                        <a href="tel:+977 986-0326973" rel="dns-prefetch">
                          +977 986-0326973
                        </a>
                      </p>
                    </article>
                    <article>
                      <p className="text-muted">Email</p>
                      <p className="para">
                        <a
                          href="mailto:info@digitalghumti.com"
                          rel="dns-prefetch"
                        >
                          info@digitalghumti.com
                        </a>
                      </p>
                      <p className="para">
                        <a
                          href="mailto:digitalghumti2019@gmail.com"
                          rel="dns-prefetch"
                        >
                          digitalghumti2019@gmail.com
                        </a>
                      </p>
                    </article>
                    <article>
                      <p className="text-muted">Working Hours</p>
                      <p className="para">Always Open</p>
                    </article>
                    <article>
                      <p className="text-muted">Office</p>

                      <address>
                        <p className="para">
                          44200 Bharatpur metropolitan - 10 <br />
                          Budhanilkantha, 44600 Kathmandu Nepal
                        </p>
                      </address>
                    </article>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Contact;
