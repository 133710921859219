import React from "react";

const AsideRight = ({ client, projectType, role, location }) => {
  return (
    <aside className="aside__right">
      <article className="description">
        <ul>
          <li>
            <p className="label">Client</p>
            <h2 className="card__title">{client}</h2>
          </li>
          <li>
            <p className="label">Project Type</p>
            <h2 className="card__title">{projectType}</h2>
          </li>
          <li>
            <p className="label">Role</p>
            <h2 className="card__title">{role}</h2>
          </li>
          <li>
            <p className="label">Location</p>
            <h2 className="card__title">{location}</h2>
          </li>
        </ul>
      </article>

      <article className="shortcut--wrapper">
        <div className="shortcut">
          <kbd>
            <span className="box">
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L2 4.98596L6 1"
                  stroke="#BDBDBD"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
              </svg>
            </span>
            Prev
          </kbd>
          <kbd>
            <span className="box">
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 9L5 4.98596L1 1"
                  stroke="#BDBDBD"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
              </svg>
            </span>
            Next
          </kbd>

          <kbd>
            <span className="box">esc</span>
            Close
          </kbd>
        </div>
      </article>
    </aside>
  );
};

export default AsideRight;
