import { TEAM_REQUEST, TEAM_SUCCESS, TEAM_FAILURE } from "../Actions/teamTypes";

const initialState = {
  loading: true,
  data: [],
  error: "",
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEAM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TEAM_SUCCESS:
      return {
        loading: false,
        data: action.playload,
        error: "",
      };
    case TEAM_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.playload,
      };
    default:
      return state;
  }
};

export default teamReducer;
