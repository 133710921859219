import {
  LOCATION_REQUEST,
  LOCATION_SUCCESS,
  LOCATION_FAILURE,
} from "../Actions/LocationTypes";

const initialState = {
  loading: false,
  country: [],
  error: "",
};

const Location = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOCATION_SUCCESS:
      return {
        loading: false,
        country: action.playload,
        error: "",
      };
    case LOCATION_FAILURE:
      return {
        loading: false,
        country: [],
        error: action.playload,
      };
    default:
      return state;
  }
};

export default Location;
