/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import Trail from "./NavTrail";
import { NavLink, Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Navigation = () => {
  const [open, set] = useState(false);

  const smile = useRef(null);
  const hamburger = useRef(null);

  const [navigation, setNavigation] = useState(false);
  const homepage = useSelector((state) => state.NavigationMargin);
  const footer = document.getElementById("footer");
  const roadmap = document.getElementById("roadmap");

  const mouseMove = (e) => {
    let elem = e.currentTarget;
    elem.classList.add("hover");
    let x =
      e.clientX -
      (elem.getClientRects()[0].x + elem.getClientRects()[0].width / 2);
    let y =
      e.clientY -
      (elem.getClientRects()[0].y + elem.getClientRects()[0].height / 2);

    // console.log("x", x);

    elem.childNodes[0].style.transform = `translate3d(${x * 0.5}px,${
      y * 0.3
    }px,0)`;
  };
  const mouseLeave = (e) => {
    let elem = e.currentTarget;
    elem.classList.remove("hover");
  };

  const hamburgerBtn = (e) => {
    setNavigation((navigation) => !navigation);
    set((open) => !open);
    document.querySelector("body").classList.toggle("overflow", !open);
    hamburger.current.classList.toggle("active");
    if (footer) {
      footer.classList.toggle("fadein", !open);
    }
    if (roadmap) {
      roadmap.classList.toggle("fadein", !open);
    }
  };

  return (
    <div>
      <section
        className={
          "navigation bg--primary " +
          (navigation ? " active " : " ") +
          (!homepage ? " margin__right " : " ")
        }
      >
        <div className="section__rule vh">
          <div className="row g-0 ">
            <aside className="col-md-6 left">
              <div className="screen__inner">
                <Trail open={open} key="navigation" className="white">
                  <span>Let's think </span>
                  <span>big together </span>
                </Trail>
                <Link to="/contact/projectform">
                  <button onClick={(e) => hamburgerBtn(e)}>
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={"animate__animated "}
                      ref={smile}
                    >
                      <path
                        d="M11 20C16.2467 20 20.5 15.7467 20.5 10.5C20.5 5.25329 16.2467 1 11 1C5.75329 1 1.5 5.25329 1.5 10.5C1.5 15.7467 5.75329 20 11 20Z"
                        stroke="#00AAE7"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.5417 11.2916C16.5417 13.9041 14.0875 16.0416 11 16.0416C7.91254 16.0416 5.45837 13.9041 5.45837 11.2916"
                        stroke="#00AAE7"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.83329 7.33333C8.27052 7.33333 8.62496 6.97889 8.62496 6.54167C8.62496 6.10444 8.27052 5.75 7.83329 5.75C7.39607 5.75 7.04163 6.10444 7.04163 6.54167C7.04163 6.97889 7.39607 7.33333 7.83329 7.33333Z"
                        stroke="#00AAE7"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.1667 7.33333C14.6039 7.33333 14.9583 6.97889 14.9583 6.54167C14.9583 6.10444 14.6039 5.75 14.1667 5.75C13.7294 5.75 13.375 6.10444 13.375 6.54167C13.375 6.97889 13.7294 7.33333 14.1667 7.33333Z"
                        stroke="#00AAE7"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    okay, let's begin
                  </button>
                </Link>
              </div>
            </aside>

            <aside className="col-md-6 right">
              <ul className="nav flex-column">
                <li className="nav-item" key="0">
                  <NavLink
                    className="nav-link nav-link--lg"
                    to="/about-us"
                    onClick={(e) => hamburgerBtn(e)}
                  >
                    About
                  </NavLink>
                </li>

                <li className="nav-item" key="2">
                  <NavLink
                    className="nav-link nav-link--lg"
                    to="/expertise"
                    onClick={(e) => hamburgerBtn(e)}
                  >
                    Expertise
                  </NavLink>
                </li>
                <li className="nav-item" key="3">
                  <NavLink
                    className="nav-link nav-link--lg mb-3"
                    to="/projects"
                    onClick={(e) => hamburgerBtn(e)}
                  >
                    Projects
                  </NavLink>
                </li>

                <li className="nav-item" key="6">
                  <NavLink
                    className="nav-link"
                    to="/blog"
                    onClick={(e) => hamburgerBtn(e)}
                  >
                    Blog
                  </NavLink>
                </li>
                <li className="nav-item" key="7">
                  <NavLink
                    className="nav-link"
                    to="/contact"
                    onClick={(e) => hamburgerBtn(e)}
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </aside>
          </div>
        </div>
      </section>
      {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid

        <a
          className="hamburger"
          onClick={(e) => hamburgerBtn(e)}
          ref={hamburger}
          rel="dns-prefetch"
          onMouseMove={(e) => {
            mouseMove(e);
          }}
          onMouseLeave={(e) => {
            mouseLeave(e);
          }}
        >
          <div>
            <div className="text-wrapper">
              <p className="text unhide">Menu</p>
              <p className="text hide">Close</p>
            </div>

            <div className="burger-line">
              <span></span>
              <span></span>
            </div>
          </div>
        </a>
      }
    </div>
  );
};

export default Navigation;
