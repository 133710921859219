import React from "react";
import "../css/component_css/template.scss";
import "../css/otherstyle.scss";

import mockup from "../Video/service.mp4";

// import ScreenTemplate from "../Template/ScreenTemplate";
import AboutusDes from "../Components/AboutusDes";

import Team from "../Template/Team";

import { Helmet } from "react-helmet-async";

const AboutUs = ({ scrollTop }) => {
  // const tagline = false;

  // const screenTitle = [
  //   {
  //     title: "We are the",
  //     class: false,
  //   },
  //   {
  //     titleSecpmdary: "Digital",
  //     title: "Future",
  //     class: false,
  //   },
  // ];

  return (
    <>
      <Helmet>
        <title>Digital Ghumti - About us</title>
      </Helmet>
      <main className="other-page about-us-page">
        <section className="screen screen--template">
          <div className="section__rule g-0">
            <div className="row no-gutter">
              <aside className="col">
                <div className="screen__inner">
                  <h2
                    className="section__title  "
                    style={{ lineHeight: "1.3" }}
                  >
                    We are the
                    <br />
                    <span className="text--secondary">Digital</span> Future
                  </h2>
                </div>
              </aside>
              <aside className="col imgage--wrapper">
                <div className="image">
                  <video muted={true} autoPlay={true} loop>
                    <source src={mockup} type="video/mp4" />
                    Think Outside The Ghumti, About us
                  </video>
                </div>
              </aside>
            </div>
            <h1 className="section__title section__title--lg mobile--only">
              About
            </h1>
          </div>
        </section>

        <AboutusDes />
        <Team scrollTop={scrollTop} />
      </main>
    </>
  );
};

export default AboutUs;
