import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import mockup1 from "../Video/DG_main.mp4";
import mockup2 from "../Video/DG_loop_robot.mp4";

import Trail from "./Trail";
import VisibilitySensor from "react-visibility-sensor";

const Screen = () => {
  const [videoEndProps, setVideoEndProps] = useState(false);
  const video = useRef(null);

  const videoVisibility = (isVisible) => {
    if (isVisible) {
      document.querySelector("header").classList.remove("white");
      document.querySelector(".hamburger").classList.remove("white");
      // video.current.currentTime = 0;
      // video.current.play();
    }
    // if(isVisible &&)
  };

  useEffect(() => {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };
    let observer = new IntersectionObserver((entries, observer) => {
      if (entries[0].isIntersecting) {
        setVideoEndProps(false);
        if (video?.current) {
          setTimeout(() => {
            video.current.currentTime = 0;
            video.current.play();
          }, 1500);
        }
      }
    }, options);
    observer.observe(video?.current);
    return () => clearTimeout();
  }, []);

  return (
    <VisibilitySensor onChange={videoVisibility}>
      <section className="screen " data-anchor="page 1">
        <div className="section__rule g-0">
          <div className="row no-gutter">
            <aside className="col aside__left px-0">
              <div className="screen__inner">
                <Trail open={true} key="screen">
                  <span> Think </span>
                  <span> Outside the </span>
                  <span className="text--secondary">Ghumti</span>
                </Trail>
                <Link to="/contact/projectform" className="section__link">
                  <svg
                    width="23"
                    height="16"
                    viewBox="0 0 23 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="g"
                  >
                    <path
                      d="M16.226 15.0383L21.1393 15.0383C22.3174 12.8457 23.0001 10.3455 23.0001 7.68492C23.0001 5.01095 22.3174 2.48405 21.1259 0.291392L16.1992 0.291392C17.8995 2.2835 18.9169 4.87725 18.9169 7.68492C18.9169 10.4792 17.8995 13.0462 16.226 15.0383Z"
                      fill="#BDBDBD"
                    />
                    <path
                      d="M14.7265 7.68492C14.7265 3.62048 11.4331 0.331501 7.36325 0.331502C3.2934 0.331502 4.01727e-05 3.62048 4.03504e-05 7.68492C-0.0133471 11.736 3.28002 15.0383 7.34986 15.0383C11.4197 15.0383 14.7265 11.736 14.7265 7.68492ZM4.06989 7.68492C4.06989 5.86662 5.54253 4.39593 7.36325 4.39593C9.17058 4.39593 10.6566 5.86662 10.6566 7.68492C10.6566 9.50321 9.18397 10.9739 7.36325 10.9739C5.54253 10.9605 4.06989 9.48985 4.06989 7.68492Z"
                      fill="#BDBDBD"
                    />
                  </svg>
                  Let's Think
                </Link>
              </div>
              <span className="border-left"></span>
            </aside>

            <aside className="col imgage--wrapper aside__right">
              <div className="image">
                <video
                  title="Think Outside The Ghumti"
                  muted={true}
                  autoPlay={true}
                  loop
                >
                  <source src={mockup2} type="video/mp4" />
                  Think Outside The Ghumti
                </video>
                <video
                  title="Think Outside The Ghumti"
                  muted={true}
                  // autoPlay={true}
                  // preload="true"
                  // lazy
                  className={videoEndProps ? " active " : " "}
                  onEnded={() => {
                    setVideoEndProps(true);
                  }}
                  ref={video}
                >
                  <source src={mockup1} type="video/mp4" />
                  Think Outside The Ghumti
                </video>
              </div>
            </aside>
          </div>
        </div>
      </section>
    </VisibilitySensor>
  );
};

export default Screen;
