import React, { useRef } from "react";

import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { Helmet } from "react-helmet-async";

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 8}px,${y / 8}px,0)`;
const trans4 = (x, y) => `translate3d(${x / 5}px,${y / 5}px,0)`;

const PageNotFound = () => {
  const svg = useRef(null);

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  const happyMode = (e, condition) => {
    svg.current.classList.toggle("active", condition);
  };

  return (
    <>
      <Helmet>
        <title>Digital Ghumti - 404 Page Not Found</title>
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dem2xvk2e/image/upload/v1619978255/Share/404_fspyy0.png"
        />
      </Helmet>
      <main
        className="page-404"
        onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      >
        <section className="screen">
          <div className="section__rule vh">
            <article>
              <h1 className="section__title">OOPS !</h1>
              <p>
                Looks like you are sad.
                <br /> Go
                <Link
                  to="/"
                  onMouseEnter={(e) => {
                    happyMode(e, true);
                  }}
                  onMouseLeave={(e) => {
                    happyMode(e, false);
                  }}
                >
                  <b>back home</b>
                </Link>
                make a smile.
              </p>
              <div className="wrapper--404">
                <svg
                  width="474"
                  height="257"
                  viewBox="0 0 474 257"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  ref={svg}
                  // className="active"
                >
                  <g>
                    <animated.g
                      className="logoItem"
                      style={{ transform: props.xy.interpolate(trans1) }}
                    >
                      <path
                        d="M153.095 256.625V201.763C177.584 188.542 205.58 181.045 235.348 181.045C265.286 181.045 293.453 188.61 318.043 202.002V257C295.735 238.054 266.852 226.604 235.348 226.604C204.048 226.604 175.37 237.883 153.095 256.625Z"
                        fill="#00AAE7"
                      />
                      <path
                        d="M235.348 164.654C280.749 164.654 317.567 127.818 317.567 82.3954C317.601 36.9041 280.783 0.0681152 235.348 0.0681152C189.948 0.0681152 153.13 36.9041 153.13 82.3613C153.13 127.818 189.948 164.654 235.348 164.654ZM235.348 45.6275C255.614 45.6275 272.064 62.1202 272.064 82.3613C272.064 102.602 255.614 119.095 235.348 119.095C215.083 119.095 198.633 102.602 198.633 82.3613C198.633 62.1202 215.117 45.6275 235.348 45.6275Z"
                        fill="#03256C"
                      />
                    </animated.g>
                    <animated.path
                      d="M76.6329 134.634H0V103.148L72.069 0H115.631V100.865H134.329V134.634H115.631V164.314H76.6329V134.634ZM79.3576 100.865V42.9015L41.0412 100.865H79.3576Z"
                      fill="#03256C"
                      style={{ transform: props.xy.interpolate(trans2) }}
                    />
                    <animated.path
                      style={{ transform: props.xy.interpolate(trans4) }}
                      d="M416.304 134.634H339.671V103.148L411.74 0H455.302V100.865H474V134.634H455.302V164.314H416.304V134.634ZM419.029 100.865V42.9015L380.713 100.865H419.029Z"
                      fill="#03256C"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="474" height="257" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </article>
          </div>
        </section>
      </main>
      <footer>
        <div className="section__rule">
          <div className="footer--bottom">
            <p className="copyright">© 2021 Digital Ghumti Pvt.Ltd.</p>
            <a
              href="https://dribbble.com/samirjay49"
              target="_blank"
              rel="noreferrer"
            >
              UI/UX <span className="text--secondary">Samirjay Art</span>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default PageNotFound;
