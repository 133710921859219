import React, { useEffect, useState } from "react";

// import pp1 from "../images/Team/abhishek.webp";
// import pp2 from "../images/Team/samirjay.webp";
// import pp3 from "../images/Team/anil.webp";
// import pp4 from "../images/Team/Sanchita .webp";
// import pp5 from "../images/Team/rajeev.webp";
// import pp6 from "../images/Team/sankalpa.webp";
// import pp7 from "../images/Team/balram.webp";
// import pp8 from "../images/Team/niranjan.webp";
// import Rita from "../images/Team/Rita.webp";
// import Rujisha from "../images/Team/rujisha.webp";
// import Niraj from "../images/Team/Niraj_Aryal.webp";
// import Sparsh from "../images/Team/sparsh.webp";

import TeamCard from "./TeamCard";

import { connect } from "react-redux";
import { teamAPI } from "../Actions/teamAPI";
import { Skeleton } from "@material-ui/lab";

const Team = ({ teamAPI, data }) => {
  // let { scrollTop } = props;
  const [teams, setTeams] = useState([]);
  const [missed, setMissed] = useState([]);

  //title
  const open = true;
  useEffect(() => {
    teamAPI();
  }, [teamAPI]);
  useEffect(() => {
    console.log("missed", missed);
  }, [missed]);

  useEffect(() => {
    if (data?.data?.data?.length > 0) {
      setTeams(
        data?.data?.data.filter((data) => {
          return data.missed === false;
        })
      );
      setMissed(
        data?.data?.data.filter((data) => {
          return data.missed === true;
        })
      );
    }
  }, [data]);

  return (
    <section className="project--template ">
      <div className="section__rule">
        <div className="d-flex">
          <aside className="aside--left">
            <div className="aside--left__top">
              <h2 className="section__title  ">
                <span> Meet our </span> <br />
                <span>thriving</span> <br />
                <span className="text--secondary">experts</span>
              </h2>
              <h2 className="section__title section__title--lg">Team</h2>
            </div>
          </aside>

          <aside className="aside--right">
            <div className={"grid card__margin " + (open ? " active " : " ")}>
              {data?.error !== "" ? (
                <span>something went wrong !</span>
              ) : data?.loading ? (
                <>
                  <div className="testi--skeleton team--skeleton">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={300}
                      className="rect"
                    />
                    <div className="d-flex">
                      <Skeleton variant="circular" className="circle" />
                      <div>
                        <Skeleton variant="text" className="text" />
                        <Skeleton variant="text" className="text" />
                      </div>
                    </div>
                  </div>
                  <div className="testi--skeleton team--skeleton">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={300}
                      className="rect"
                    />
                    <div className="d-flex">
                      <Skeleton variant="circular" className="circle" />
                      <div>
                        <Skeleton variant="text" className="text" />
                        <Skeleton variant="text" className="text" />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                teams.map((team, index) => {
                  return <TeamCard {...team} index={index} key={index} />;
                })
              )}
            </div>

            {missed.length > 0 && (
              <h2 className="section__title section__title--sm">
                They helped us reach so far
              </h2>
            )}

            <div className={"grid " + (open ? " active " : " ")}>
              {data?.error !== "" ? (
                <span>something went wrong !</span>
              ) : data?.loading ? (
                <>
                  <div className="testi--skeleton team--skeleton">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={300}
                      className="rect"
                    />
                    <div className="d-flex">
                      <Skeleton variant="circular" className="circle" />
                      <div>
                        <Skeleton variant="text" className="text" />
                        <Skeleton variant="text" className="text" />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                missed.map((team, index) => {
                  return <TeamCard {...team} index={index} key={index} />;
                })
              )}
            </div>
          </aside>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.teamReducer,
  };
};

export default connect(mapStateToProps, { teamAPI })(Team);
