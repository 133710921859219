import React from "react";
import { Link } from "react-router-dom";

const Aboutus_des = () => {
  return (
    <section className="clients bg--light">
      <div className="section__rule vh">
        <h1 className="section__title section__title--lg desktop--only">
          About
        </h1>
        <div className="d-flex">
          <article className="item client__card--wrapper">
            <div className="client__card">
              <p className="card__title">
                Huge commercials may be attractive but may not always fit your
                need. We are the genuine solutions promising you to elevate your
                business from scratch to become renowned in the future.
              </p>
            </div>
            <Link to="/expertise" className="section__link">
              <svg
                width="23"
                height="16"
                viewBox="0 0 23 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="g"
              >
                <path
                  d="M16.226 15.0383L21.1393 15.0383C22.3174 12.8457 23.0001 10.3455 23.0001 7.68492C23.0001 5.01095 22.3174 2.48405 21.1259 0.291392L16.1992 0.291392C17.8995 2.2835 18.9169 4.87725 18.9169 7.68492C18.9169 10.4792 17.8995 13.0462 16.226 15.0383Z"
                  fill="#BDBDBD"
                ></path>
                <path
                  d="M14.7265 7.68492C14.7265 3.62048 11.4331 0.331501 7.36325 0.331502C3.2934 0.331502 4.01727e-05 3.62048 4.03504e-05 7.68492C-0.0133471 11.736 3.28002 15.0383 7.34986 15.0383C11.4197 15.0383 14.7265 11.736 14.7265 7.68492ZM4.06989 7.68492C4.06989 5.86662 5.54253 4.39593 7.36325 4.39593C9.17058 4.39593 10.6566 5.86662 10.6566 7.68492C10.6566 9.50321 9.18397 10.9739 7.36325 10.9739C5.54253 10.9605 4.06989 9.48985 4.06989 7.68492Z"
                  fill="#BDBDBD"
                ></path>
              </svg>
              How do we process
            </Link>

            <h2 className="section__title section__title--sm">
              <span className="quote text--secondary">“</span>
              <span>Delivering Big Impact in Small Details</span>
            </h2>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Aboutus_des;
