import axios from "axios";
import { SINGLE_REQUEST, SINGLE_SUCCESS, SINGLE_FAILURE } from "./singleTypes";

export const singleRequest = () => {
  return {
    type: SINGLE_REQUEST,
  };
};

export const singleSuccess = (dataSingle) => {
  return {
    type: SINGLE_SUCCESS,
    playload: dataSingle,
  };
};

export const singleFailure = (error) => {
  return {
    type: SINGLE_FAILURE,
    playload: error,
  };
};
export const singleAPI = (id) => {
  return (dispatch) => {
    dispatch(singleRequest());
    axios
      .get("https://backend.digitalghumti.com/api/project/" + id)
      .then((response) => {
        const dataSingle = response.data;
        dispatch(singleSuccess(dataSingle));
      })
      .catch((error) => {
        const errorMessage = error.message;
        dispatch(singleFailure(errorMessage));
      });
  };
};
