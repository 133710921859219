import React from 'react'


const Roadmap_Card = (props) => {

     const {svg, title, para, index} = props;

     return (
          <div className="roadmap__wrapper" key={index}>
               <article className="aside__right__top">
                    <img src={svg} alt={title} />
                    <h2 className="section__title white">{title}</h2>
               </article>
               <article className="item">
                    <div className="roadmap__card" >
                         <p className="card__title">{para}</p>
                    </div>
               </article>
          </div>
     )
}

export default Roadmap_Card