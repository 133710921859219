import React from "react";
import { Link } from "react-router-dom";

const ProjectsCard = (data) => {
  // console.log("projects", projects);
  const { id, image50, title, client, role } = data;
  // let image = images[0].image;

  return (
    <article className="item" key={id} index={id}>
      <Link
        to={{
          pathname: `/projects/${id}`,
          state: {
            background: {
              pathname: "/projects",
              search: "",
              hash: "",
              state: undefined,
            },
          },
        }}
      >
        <div className="card">
          <div className="card__img">
            <img
              src={image50}
              alt={"A Project of " + client + " by Digital Ghumti"}
            />
          </div>
          <div className="card__body">
            <p className="para ">/ {role}</p>
            <h2
              className="card__title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        </div>
      </Link>
    </article>
  );
};

export default ProjectsCard;
