import React, { useState, useEffect, useRef } from "react";
import Trail from "./Trail";

const Brand = () => {
  const [open, set] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        set(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref]);

  const brandsLogo = [
    {
      img: "https://res.cloudinary.com/dem2xvk2e/image/upload/v1625919071/Brand/Benelli-Logo_1_bpzchb.png",
      name: "Benelli",
    },
    {
      img: "https://res.cloudinary.com/dem2xvk2e/image/upload/v1625919278/Brand/brand8_wyuhrx.png",
      name: "Ataali",
    },
    {
      img: "https://res.cloudinary.com/dem2xvk2e/image/upload/v1625919278/Brand/brand5_h98fq9.png",
      name: "BP",
    },
    {
      img: "https://res.cloudinary.com/dem2xvk2e/image/upload/v1625919071/Brand/salyani-technologies_1_ukx4ke.png",
      name: "Salyani",
    },
    {
      img: "https://res.cloudinary.com/dem2xvk2e/image/upload/v1626965038/Brand/logo_6_mmo2n1.png",
      name: "Chitwan Food Land",
    },
    {
      img: "https://res.cloudinary.com/dem2xvk2e/image/upload/v1625919277/Brand/brand3_yfebi8.png",
      name: "Cinemaghar",
    },
    {
      img: "https://res.cloudinary.com/dem2xvk2e/image/upload/v1625919278/Brand/brand6_d1xtby.png",
      name: "CGR",
    },
    {
      img: "https://res.cloudinary.com/dem2xvk2e/image/upload/v1625919279/Brand/brand12_tczgbe.png",
      name: "Sri Dental Clinic",
    },
    {
      img: "https://res.cloudinary.com/dem2xvk2e/image/upload/v1625919071/Brand/b25d949f-2f0f-40df-a0fe-bfe896957ade_1_gwpaqv.png",
      name: "WEAN",
    },
    {
      img: "https://res.cloudinary.com/dem2xvk2e/image/upload/v1625919071/Brand/51632668_254294728829974_4681851872644956160_n_1_n20jpy.png",
      name: "Vito",
    },
    {
      img: "https://res.cloudinary.com/dem2xvk2e/image/upload/v1625919071/Brand/abf9df8b-3126-4a6d-a60a-328465050eaa_1_coyu3s.png",
      name: "The Balaz",
    },
    {
      img: "https://res.cloudinary.com/dem2xvk2e/image/upload/v1625919278/Brand/brand2_upuyie.png",
      name: "EatWell",
    },
  ];

  return (
    <section className="brand" ref={ref}>
      <div className="section__rule vh">
        <div className="d-flex">
          <aside className="brand--left">
            <Trail open={window.innerWidth > 840 ? open : true} key="services">
              <span> Some </span>
              <span>Belive in an </span>
              <span className="text--secondary">affinity</span>
            </Trail>
            {/* <h2 className="section__title">
              Some believe <br />
              in an <span className="text--secondary">affinity</span>
            </h2> */}
          </aside>

          <aside className={"brand--right grid" + (open ? " active " : " ")}>
            {brandsLogo.map((brand, index) => {
              return (
                <div key={index} className="brand__card" title={brand.name}>
                  <img src={brand.img} alt={brand.name} />
                </div>
              );
            })}
          </aside>
        </div>
      </div>
    </section>
  );
};

export default Brand;
