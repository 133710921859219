import React from "react";
import Tilt from "react-tilt";

const TeamCard = (props) => {
  let { image, name, position, expertise, index } = props;

  return (
    <article className="item" key={index}>
      {window.innerWidth > 600 ? (
        <Tilt className="Tilt" options={{ max: 25, scale: 1.02 }}>
          <div className={"card "}>
            <div className="card__body">
              <h2
                className="card__title"
                dangerouslySetInnerHTML={{ __html: name }}
              />
              {position ? <p className={"para "}>{position}</p> : false}

              <h2
                className={
                  "deg " +
                  (expertise === "Social Media <br/> Associate"
                    ? " left "
                    : " ")
                }
                dangerouslySetInnerHTML={{ __html: expertise }}
              />
            </div>
            <div className="Tilt-inner card__img ">
              <img src={image} alt={name} />
            </div>
          </div>
        </Tilt>
      ) : (
        <div className="card ">
          <div className="card__body">
            <h2
              className="card__title"
              dangerouslySetInnerHTML={{ __html: name }}
            />
            {position ? <p className={"para "}>{position}</p> : false}

            <h2
              className={
                "deg " +
                (expertise === "Social Media <br/> Associate" ? " left " : " ")
              }
              dangerouslySetInnerHTML={{ __html: expertise }}
            />
          </div>
          <div className="Tilt-inner card__img ">
            <img src={image} alt={name} />
          </div>
        </div>
      )}
    </article>
  );
};

export default TeamCard;
