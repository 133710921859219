import {
  FETCH_ROADMAP_REQUEST,
  FETCH_ROADMAP_SUCCESS,
  FETCH_ROADMAP_FAILURE,
} from "../Actions/roadmapTypes";

const initialState = {
  loading: false,
  roadmap:[],
  error: '',
}

const RoadmapData = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROADMAP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ROADMAP_SUCCESS:
      return {
        loading: false,
        roadmap: action.playload,
        error: "",
      };
    case FETCH_ROADMAP_FAILURE:
      return {
        loading: false,
        roadmap:[],
        error:  action.playload,
      };
    default:
      return state;
  }
};

export default RoadmapData;
