import {
  SINGLE_REQUEST,
  SINGLE_SUCCESS,
  SINGLE_FAILURE,
} from "../Actions/singleTypes";

const initialState = {
  loading: true,
  data: [],
  error: "",
};

const singleAPI = (state = initialState, action) => {
  switch (action.type) {
    case SINGLE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SINGLE_SUCCESS:
      return {
        loading: false,
        data: action.playload,
        error: "",
      };
    case SINGLE_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.playload,
      };
    default:
      return state;
  }
};

export default singleAPI;
