import React, { useEffect } from "react";
import Main from "../Components/Main";
import { Helmet } from "react-helmet-async";

const Index = (props) => {
  useEffect(() => {
    document.querySelector("header").classList.add("fixed");
    document.querySelector("header").classList.remove("white");
    document.querySelector(".hamburger").classList.remove("white");
  }, []);

  return (
    <>
      <Helmet>
        <title>Digital Ghumti - Home</title>
      </Helmet>
      <Main {...props} />
    </>
  );
};

export default Index;
