import React from "react";
import loaderVideo from "../Video/loader.mp4";

const Loader = () => {
  return (
    <video muted={true} autoPlay={true} loop={true} className="loader--video">
      <source src={loaderVideo} type="video/mp4" />
    </video>
  );
};

export default Loader;
