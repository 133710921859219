import axios from "axios";
import { TEAM_REQUEST, TEAM_SUCCESS, TEAM_FAILURE } from "./teamTypes";

export const teamRequest = () => {
  return {
    type: TEAM_REQUEST,
  };
};

export const teamSuccess = (data) => {
  return {
    type: TEAM_SUCCESS,
    playload: data,
  };
};

export const teamFailure = (error) => {
  return {
    type: TEAM_FAILURE,
    playload: error,
  };
};
// backend.digitalghumti.com/api/team
export const teamAPI = () => {
  return (dispatch) => {
    dispatch(teamRequest());
    axios
      .get(process.env.REACT_APP_API + "team")
      .then((response) => {
        const data = response.data;
        dispatch(teamSuccess(data));
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.log(errorMessage);
        dispatch(teamFailure(errorMessage));
      });
  };
};
