import {
  ENQUERY_REQUEST,
  ENQUERY_SUCCESS,
  ENQUERY_FAILURE,
} from "../Actions/enqueryTypes";

const initialState = {
  loading: true,
  data: [],
  error: {},
};

const enqueryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENQUERY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ENQUERY_SUCCESS:
      return {
        loading: false,
        data: action.playload,
        error: "",
      };
    case ENQUERY_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.playload,
      };
    default:
      return state;
  }
};

export default enqueryReducer;
