import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const ContactHeader = ({ active }) => {
  const enq = useRef(null);
  useEffect(() => {
    if (window.innerWidth < 600 && active === 2) {
      if (enq?.current) {
        enq.current.scrollLeft = 300;
      }
    }
  }, [active]);
  return (
    <article className="card__wrapper" ref={enq}>
      <Link
        to={{ pathname: "/contact/projectform", state: { scroll: true } }}
        className={active === 1 ? " active " : " "}
      >
        <div className="card">
          <h2 className="card__title">Start a project</h2>
          <p className="para">
            Looking to discuss a <br /> project with us?
          </p>
        </div>
      </Link>
      <Link
        to={{ pathname: "/contact/enqueryform", state: { scroll: true } }}
        className={active === 2 ? " active " : ""}
      >
        <div className="card hello">
          <h2 className="card__title">General Enquiry</h2>
          <p className="para">
            Are you ready to share <br /> with us?
          </p>
        </div>
      </Link>
    </article>
  );
};

export default ContactHeader;
