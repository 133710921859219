import { createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";

import allReducers from "./Reducers";

const store = createStore(
  allReducers,
  applyMiddleware(thunk)
  // composeWithDevTools(applyMiddleware(thunk))
);
// console.log('initial store', store.getState())

export default store;
