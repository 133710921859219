import React, { useEffect, useState, useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";

import Screen from "./Screen";
import Brand from "./Brand";
import Clients from "./Clients";
import Roadmap from "./Roadmap";
import Footer from "./Footer";
import { Helmet } from "react-helmet-async";

const Main = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const pageable = useRef(null);
  const roadmap = useRef(null);

  function visibility(isVisible) {
    // if (isVisible) {
    if (isVisible) {
      document.querySelector("header").classList.add("white");
      document.querySelector(".hamburger").classList.add("white");
      // }
      roadmap.current.focus();
      roadmap.current.click();
    } else {
      document.querySelector("header").classList.remove("white");
      document.querySelector(".hamburger").classList.remove("white");
    }
  }

  function visibilityWhite(isVisible) {
    if (isVisible) {
      document.querySelector("header").classList.remove("white");
      document.querySelector(".hamburger").classList.remove("white");
    }
  }

  useEffect(() => {
    pageable.current.addEventListener("scroll", (e) => {
      setScrollTop(e.target.scrollTop);
      if (navigator.userAgent.indexOf("Chrome") !== -1) {
        document.querySelector("body").classList.add("chrome");
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Digital Ghumti" />
        <meta
          name="description"
          content="Our goal is to fully understand each client's business and the environment in which it operates.We look to completely understand the target audience and how they will interact with the digital products we deliver."
        />
        <meta property="og:description" content=" Think Outside the Ghumti" />

        <meta
          property="og:image"
          content="https://res.cloudinary.com/dem2xvk2e/image/upload/v1619978252/Share/image_3_owln6n.png"
        />
        <meta
          property="og:video"
          content="https://res.cloudinary.com/dem2xvk2e/video/upload/v1619977624/Projects/video/DG_main_kaqvjg.mp4"
        />
        <meta property="og:url" content="https://digitalghumti.com/" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta property="og:site_name" content="Digital Ghumti" />
        <meta name="twitter:image:alt" content="Think Outside the Ghumti" />
      </Helmet>
      <main className="page--home" ref={pageable}>
        <VisibilitySensor
          onChange={visibilityWhite}
          intervalDelay={0}
          minTopValue={"100px"}
        >
          <Screen />
        </VisibilitySensor>
        <VisibilitySensor onChange={visibility} intervalDelay={0}>
          <Roadmap roadmap={roadmap} />
        </VisibilitySensor>
        <Brand />
        <Clients scrollTop={scrollTop} />
        <Footer scrollTop={scrollTop} />
      </main>
    </>
  );
};

export default Main;
