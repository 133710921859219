import React, { useRef } from "react";
import Marquee from "react-fast-marquee";

const ExpertiseHeader = () => {
  const sectionTitle = useRef(null);
  // const sectionSvg = useRef(null);

  return (
    <section className="expertiseHeader">
      <div className="section__rule w-100" ref={sectionTitle}>
        <Marquee
          className="expertiseHeader__text"
          gradient={false}
          speed={60}
          // delay={5}
        >
          <h2 className="section__title  text-outline text-outline--font">
            {"  "} Leading digitally in the land of Nepal {"  "}
          </h2>
        </Marquee>
        <Marquee
          className="expertiseHeader__text"
          gradient={false}
          direction={"right"}
          speed={60}
        >
          <h2 className="section__title  text-outline text-outline--font">
            {"  "} Leading digitally in the land of Nepal {"  "}
          </h2>
        </Marquee>
      </div>
    </section>
  );
};

export default ExpertiseHeader;
