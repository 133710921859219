import React from "react";

// import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ReactComponent as BlogIcon } from "../svg/Blog_icon.svg";

const Blog = () => {
  // console.log(props);
  // const id = props.match.params.id;
  return (
    <>
      <Helmet>
        <title>Digital Ghumti - Blog</title>
      </Helmet>
      <main className="page-submit page--blog">
        <section className="screen">
          <div className="section__rule  g-0 vh">
            <div className="row no-gutter align-items-center">
              <aside className="col  px-0">
                <div className="screen__inner">
                  <article>
                    <h2 className="section__title--md">
                      Dive deep into the pool <br />
                      of <span className="text--secondary">knowledge</span>
                    </h2>
                    <p className="section__link">Working on it</p>

                    {/* <Link to="/" className="outline">
                      Back to Home
                    </Link> */}
                  </article>
                </div>
              </aside>
              <aside className="col">
                <div className="image">
                  <div className="wrapper--404">
                    <BlogIcon />
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <div className="section__rule">
          <div className="footer--bottom">
            <p className="para copyright">© 2021 Digital Ghumti Pvt.Ltd.</p>
            <a
              href="https://dribbble.com/samirjay49"
              target="_blank"
              className="para"
              rel="noreferrer"
            >
              UI/UX <span className="text--secondary">Samirjay Art</span>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Blog;
