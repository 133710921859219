import React from "react";

const ExpRoadmapCard = (props) => {
  const { svgdark, title, index, lists, para } = props;
  return (
    <div className="roadmap__wrapper" key={index}>
      <article className="aside__right__top">
        <img src={svgdark} alt={title} />
        <h2 className="section__title">{title}</h2>
      </article>
      <article className="item">
        <div className="roadmap__card">
          <p className="card__title">{para}</p>
        </div>

        <ul className="para">
          {lists &&
            lists.map((elem, index) => {
              return (
                <li key={index}>
                  <i>•</i> {elem}
                </li>
              );
            })}
        </ul>
      </article>
    </div>
  );
};

export default ExpRoadmapCard;
