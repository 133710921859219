import React, { useEffect } from "react";
import RoadmapCard from "./RoadmapCard";

import { connect } from "react-redux";
import { fetchRoadmapList } from "../Actions";

import Skeleton from "@material-ui/lab/Skeleton";
const RoadmapList = ({ fetchRoadmapList, roadmapData }) => {
  useEffect(() => {
    fetchRoadmapList();
  }, [fetchRoadmapList]);
  return (
    <aside className="aside__right">
      {roadmapData.loading ? (
        <Skeleton animation="wave" />
      ) : roadmapData.error ? (
        <p>Something went wrong 😔</p>
      ) : (
        roadmapData &&
        roadmapData.roadmap &&
        roadmapData.roadmap.map((item, index) => {
          return <RoadmapCard {...item} index={index} key={index} />;
        })
      )}
    </aside>
  );
};

const mapStateToProps = (state) => {
  return {
    roadmapData: state.RoadmapData,
  };
};

export default connect(mapStateToProps, { fetchRoadmapList })(RoadmapList);
