import React, { useRef, useEffect } from "react";

import ExpertiseDetails from "./ExpertiseDetails";

const ExpertiseBody = ({ scrollTop }) => {
  const progressbar = useRef(null);
  const sectionTitle = useRef(null);

  useEffect(() => {
    if (window.innerWidth > 840) {
      let rect,
        isInViewport = false,
        top;
      rect = sectionTitle.current.getBoundingClientRect();
      isInViewport =
        rect.top <= window.innerHeight / 2 &&
        rect.bottom > window.innerHeight - 200;

      top = rect.bottom - window.innerHeight - rect.height;
      top = ((-1 * top) / rect.height) * 100;
      if (isInViewport) {
        progressbar.current.style.height = top + "%";
      } else if (rect.bottom < window.innerHeight) {
        progressbar.current.style.height = "100%";
      }
    }
  }, [scrollTop]);

  return (
    <section className="roadmap project--template " ref={sectionTitle}>
      <div className="section__rule">
        <div className="d-flex">
          <aside className="aside--left">
            <div className="aside--left__top">
              <div className="section__title--lg__wrapper">
                <h2 className="section__title section__title--lg">Services</h2>
              </div>
              <div className="progress">
                <div className="progress-bar" ref={progressbar}></div>
              </div>
            </div>
          </aside>

          <ExpertiseDetails />
        </div>
      </div>
    </section>
  );
};

export default ExpertiseBody;
