import React, { useState } from "react";

const Clients_Card = (props) => {
  const { image80, name, message, index, position } = props;
  const nameHover = useState(false);

  return (
    <article className="item" key={index}>
      <div className="client__card">
        <p
          className="card__title"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <div className={"card__top " + (nameHover ? " active " : " ")}>
          <div className="card__img">
            <img src={image80} alt={"image of " + name} />
          </div>
          <svg
            width="105"
            height="227"
            viewBox="0 0 105 227"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.850594 226.168L76.2037 226.168C94.272 192.541 104.743 154.196 104.743 113.392C104.743 72.3821 94.2719 33.6279 75.9983 -3.38203e-05L0.439932 -3.05176e-05C26.5158 30.5522 42.1202 70.3316 42.1202 113.392C42.1202 156.247 26.5158 195.616 0.850594 226.168Z"
              fill="#00AAE7"
            />
          </svg>
          <div
            className="card__bottom__right"
            // onMouseEnter={() => setNameHover(true)}
            // onMouseLeave={() => setNameHover(false)}
          >
            <h2 className="card__title">{name}</h2>
            <p className="para deg">{position}</p>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Clients_Card;
