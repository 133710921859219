import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

const BottomCard = forwardRef(({ data, direction, next }, ref) => {
  const { id, image, client, role } = data;
  return (
    <article className="item" key={id}>
      <Link
        to={{
          pathname: `/projects/${id}`,
          state: {
            background: {
              pathname: "/projects",
            },
          },
        }}
        className="card card__hr"
        ref={ref}
      >
        <div className="card__img">
          <img
            src={image}
            alt={"A Project of " + client + " by Digital Ghumti"}
          />
        </div>
        <div className="card__body">
          <p className="para ">Client: {client}</p>
          <h2
            className="card__title"
            dangerouslySetInnerHTML={{ __html: role }}
          />
          {direction === "next" ? (
            <svg
              width="75"
              height="30"
              viewBox="0 0 75 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M59.0003 29.2L73.3003 14.9L59.0003 0.699951"
                  stroke="#00AAE7"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M73.7002 15H-0.299805"
                  stroke="#00AAE7"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="74.7"
                    height="29.9"
                    fill="white"
                    transform="matrix(-1 0 0 1 74.7002 0)"
                  />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              width="75"
              height="30"
              viewBox="0 0 75 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M15.6999 29.2L1.3999 14.9L15.6999 0.699951"
                  stroke="#00AAE7"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1 15H75"
                  stroke="#00AAE7"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="74.7" height="29.9" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
        </div>
      </Link>
    </article>
  );
});

export default BottomCard;
