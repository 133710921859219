import React from "react";
import logo from "../svg/DG-logo.svg";
import logoWhite from "../svg/logo.svg";

import { Link, NavLink } from "react-router-dom";

const Header = () => {
  const mouseMove = (e) => {
    let elem = e.currentTarget;
    elem.classList.add("hover");
    let x =
      e.clientX -
      (elem.getClientRects()[0].x + elem.getClientRects()[0].width / 2);
    let y =
      e.clientY -
      (elem.getClientRects()[0].y + elem.getClientRects()[0].height / 2);

    // console.log("x", x);

    elem.querySelector("span").style.transform = `translate3d(${x * 0.5}px,${
      y * 0.3
    }px,0)`;
  };
  const mouseLeave = (e) => {
    let elem = e.currentTarget;
    elem.classList.remove("hover");
  };
  return (
    <header className="primary-header fixed">
      <div className="section__rule">
        <ul className="nav">
          <li className="nav-item logo">
            <Link to="/">
              <img src={logo} alt="Degital Ghumti Logo" />
              <img
                src={logoWhite}
                alt="Degital Ghumti Logo"
                className="logo-white"
              />
            </Link>
          </li>

          <li
            className="nav-item ml-auto"
            onMouseMove={(e) => {
              mouseMove(e);
            }}
            onMouseLeave={(e) => {
              mouseLeave(e);
            }}
          >
            <NavLink className="nav-link" to="/about-us">
              <span>About</span>
            </NavLink>
          </li>
          <li
            className="nav-item"
            onMouseMove={(e) => {
              mouseMove(e);
            }}
            onMouseLeave={(e) => {
              mouseLeave(e);
            }}
          >
            <NavLink className="nav-link" to="/Projects">
              <span>Projects</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
