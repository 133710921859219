import {
  PJINITIATE_REQUEST,
  PJINITIATE_SUCCESS,
  PJINITIATE_FAILURE,
} from "../Actions/projectInitiateTypes";

const initialState = {
  loading: true,
  data: [],
  error: {},
};

const projectInitiateReducer = (state = initialState, action) => {
  switch (action.type) {
    case PJINITIATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PJINITIATE_SUCCESS:
      return {
        loading: false,
        data: action.playload,
        error: "",
      };
    case PJINITIATE_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.playload,
      };
    default:
      return state;
  }
};

export default projectInitiateReducer;
