import React, { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ContactHeader from "../PageCompontents/ContactHeader";
import { connect } from "react-redux";
import { enqueryAPI } from "../Actions/enqueryAPI";
import CircularProgress from "@material-ui/core/CircularProgress";

// input
import TextField from "@material-ui/core/TextField";

const Contact = ({ data, enqueryAPI }) => {
  const firstName = useRef(null);
  const scrollTop = useRef(null);
  const [sendingData, setData] = useState({
    name: "",
    phone: "",
    email: "",
    country: "",
    enquery: "",
  });
  let { state } = useLocation();

  const { name, phone, email, country, enquery } = sendingData;
  const [errorMessage, setErrorMessage] = useState(false);
  const [sendBtn, setSendBtn] = useState(false);

  let history = useHistory();

  const Enquiry = (e) => {
    e.preventDefault();
    setSendBtn(true);

    if (localStorage.getItem("enqName")) {
      localStorage.removeItem("enqName");
    }

    if (name) {
      localStorage.setItem("enqName", name);
    }
    enqueryAPI(sendingData);
  };

  const onChange = (event) => {
    setData({
      ...sendingData,
      [event.currentTarget.name]: event.currentTarget.value,
    });
    // console.log(event.currentTarget.name, event.currentTarget.value);
  };

  useEffect(() => {
    // console.log(data);

    if (data?.loading === false) {
      setSendBtn(false);

      if (data?.data?.status === true) {
        setErrorMessage(false);
        history.push("/Submit/" + (name || localStorage.getItem("enqName")));
        // window.location.reload(false);
      } else if (data.error) {
        setErrorMessage(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    document.querySelector("body").classList.remove("overflow");

    setTimeout(() => {
      document.querySelector("html").scrollTo(0, 0);
      // console.log(scrollTop?.current);
      scrollTop?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 100);
  }, []);

  // scroll
  const scroll = useRef(null);
  useEffect(() => {
    if (state?.scroll && scroll?.current) {
      setTimeout(() => {
        scroll?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 120);
    }
  }, [state]);

  return (
    <>
      <Helmet>
        <title>Digital Ghumti - Contact Us</title>
      </Helmet>
      <main className="other-page projects-page contact--page">
        <div ref={scrollTop}></div>
        <section className="project--template ">
          <div className="section__rule">
            <div className="d-flex">
              <aside className="aside--left">
                <div className="aside--left__top">
                  <h2 className="section__title">
                    Come <span className="text--secondary">closer</span> <br />
                    <span>to your dreams</span>
                  </h2>
                  <h2 className="section__title section__title--lg">Contact</h2>
                </div>
              </aside>

              <aside className="aside--right">
                <ContactHeader active={2} />
                <div className="swipeable-container">
                  <h2 className="section__title text--secondary " ref={scroll}>
                    Things moving
                    <br />
                    in your mind?
                  </h2>

                  <form action="#" method="post" onSubmit={(e) => Enquiry(e)}>
                    <article className="form-group text-input">
                      <div className="row ">
                        <div className="col-md-6">
                          <div className="title--small card__margin">
                            <TextField
                              name="name"
                              label="Name"
                              required={true}
                              type="text"
                              ref={firstName}
                              value={name}
                              onChange={(event) => onChange(event)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="title--small card__margin">
                            <TextField
                              name="phone"
                              label="Phone"
                              required={true}
                              type="tel"
                              value={phone}
                              onChange={(event) => {
                                onChange(event);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="title--small card__margin">
                            <TextField
                              name="email"
                              label="Email"
                              required={true}
                              type="email"
                              onChange={(event) => {
                                onChange(event);
                              }}
                              value={email}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="title--small card__margin">
                            <TextField
                              name="country"
                              label="Country"
                              required={true}
                              type="text"
                              onChange={(event) => {
                                onChange(event);
                              }}
                              value={country}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="title--small card__margin ">
                            <TextField
                              name="enquery"
                              label="Please write your message here"
                              required={false}
                              // type="text"
                              multiline
                              maxrows={4}
                              onChange={(event) => {
                                onChange(event);
                              }}
                              value={enquery}
                            />
                          </div>
                        </div>
                      </div>
                    </article>
                    {errorMessage && (
                      <p className="text-danger">
                        Error ! . check your filled twice
                      </p>
                    )}
                    <button type="submit" className="outline loader--btn">
                      {sendBtn && <CircularProgress />}
                      <span>Send Message</span>
                    </button>
                  </form>
                </div>
              </aside>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.EnqueryReducer,
  };
};

export default connect(mapStateToProps, { enqueryAPI })(Contact);
