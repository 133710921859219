import axios from "axios";
import {
  PROJECT_REQUEST,
  PROJECT_SUCCESS,
  PROJECT_FAILURE,
} from "./projectTypes";

export const projectRequest = () => {
  return {
    type: PROJECT_REQUEST,
  };
};

export const projectSuccess = (data) => {
  return {
    type: PROJECT_SUCCESS,
    playload: data,
  };
};

export const projectFailure = (error) => {
  return {
    type: PROJECT_FAILURE,
    playload: error,
  };
};
// backend.digitalghumti.com/api/project/by-type
export const projectAPI = () => {
  return (dispatch) => {
    dispatch(projectRequest());
    axios
      .get("https://backend.digitalghumti.com/api/project")
      .then((response) => {
        const data = response.data;
        dispatch(projectSuccess(data));
      })
      .catch((error) => {
        const errorMessage = error.message;
        dispatch(projectFailure(errorMessage));
      });
  };
};
