import React, { useState, useEffect, useRef } from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import ProjectTabs from "./ProjectTabs";

// fetching part
import { connect } from "react-redux";
import { projectAPI } from "../Actions/projectAPI";
import { Skeleton } from "@material-ui/lab";

const ProjectList = ({ projectAPI, data }) => {
  //title
  const open = useState(false);

  // const sectionTitle = useRef(null);
  // const sticky = useRef(null);

  const [index, setIndex] = useState(0);
  const tabWrapper = useRef(null);

  const handleChange = (event, value) => {
    setIndex(value);
    if (tabWrapper.current) {
      tabWrapper.current.scrollIntoView("smooth");
    }
  };

  const tabsTitle = [
    {
      title: window.innerWidth > 600 ? "Clear" : "All",
    },
    {
      title: "Product Design",
    },
    {
      title: "Branding",
    },
    {
      title: "Print",
    },
    {
      title: "Social Ads",
    },
    // {
    //   title: "Video",
    // },
  ];

  // fetching data only once
  useEffect(() => {
    projectAPI();
  }, [projectAPI]);

  return (
    <section className="project--template ">
      <div className="section__rule">
        <div ref={tabWrapper}></div>
        <ul className="nav nav-tabs sticky">
          <li className="nav-item  nav-link aside--left" key={1}></li>
          <li className="aside--right" key={2}>
            <Tabs
              value={index}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabsTitle.map((title, index) => {
                return <Tab label={title.title} key={index + 1} />;
              })}
            </Tabs>
          </li>
        </ul>

        <div className="d-flex">
          <aside className="aside--left">
            <div className="aside--left__top">
              <div className="section__title--lg__wrapper">
                <h2 className="section__title section__title--lg ">Projects</h2>
              </div>
            </div>
          </aside>

          <aside className="aside--right">
            {/* <SwipeableViews index={index} onChangeIndex={handleChangeIndex}> */}
            {tabsTitle.map((title, indexx) => {
              if (index === indexx) {
                if (!data?.error === "") {
                  return <p className="text-muted">Network error</p>;
                } else if (!data.loading) {
                  return (
                    <ProjectTabs
                      open={open}
                      title={title.title}
                      key={indexx}
                      data={data.data.data}
                    />
                  );
                } else
                  return (
                    <div
                      className="loader--wrapper grid"
                      key="loader--wrapper--1"
                    >
                      <div
                        className="loader--box"
                        key="loader1"
                        title="Loading"
                      >
                        <Skeleton
                          variant="rect"
                          animation="wave"
                          height={200}
                          title="Loading"
                          key="skeleton1"
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          key="skeleton2"
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          key="skeleton3"
                        />
                      </div>
                      <div className="loader--box" key="loader2">
                        <Skeleton
                          variant="rect"
                          animation="wave"
                          height={200}
                          key="skeleton1"
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          key="skeleton2"
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          key="skeleton3"
                        />
                      </div>
                      <div className="loader--box" key="loader3">
                        <Skeleton
                          variant="rect"
                          animation="wave"
                          height={200}
                          key="skeleton1"
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          key="skeleton2"
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          key="skeleton3"
                        />
                      </div>
                      <div className="loader--box" key="loader4">
                        <Skeleton
                          variant="rect"
                          animation="wave"
                          height={200}
                          key="skeleton1"
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          key="skeleton2"
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          key="skeleton3"
                        />
                      </div>
                    </div>
                  );
              } else {
                return null;
              }
            })}
            {/* </SwipeableViews> */}
          </aside>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.projectAPI,
  };
};

export default connect(mapStateToProps, { projectAPI })(ProjectList);
