import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import "../css/component_css/template.scss";
import "../css/otherstyle.scss";

// import ScreenTemplateText from "../Template/ScreenTemplateText";
import ProjectsList from "../Template/ProjectsList";

// import { useLocation } from "react-router-dom";

const Projects = () => {
  const des = `Discover the digitizing potential of your businesses with us through our creative designs and interventions.`;
  useEffect(() => {
    document.querySelector("header, .hamburger").classList.remove("white");
  }, []);

  return (
    <>
      <Helmet>
        <title>Digital Ghumti - Our Projects</title>
        <meta
          property="og:description"
          content=" Explore business-boosting concepts before taking them to market – with robust testing, project predictions and expert roadmapping."
        />
        <meta
          name="description"
          content="Explore business-boosting concepts before taking them to market – with robust testing, project predictions and expert roadmapping."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dem2xvk2e/image/upload/v1619979521/Share/Group_214_dr57ot.png"
        />
      </Helmet>
      <main className="other-page projects-page">
        <section className="screen screen--template screen--template--text">
          <div className="section__rule g-0">
            <div className="row no-gutter">
              <aside className="col">
                <div className="screen__inner">
                  <h2 className="section__title" style={{ lineHeight: "1.2" }}>
                    Transforming <br /> ideas into
                    <span className="text--secondary"> digital</span>
                    <br />
                    outcomes
                  </h2>
                  <svg
                    width="165"
                    height="358"
                    viewBox="0 0 165 358"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.649621 358L119.852 358C148.435 304.771 165 244.076 165 179.487C165 114.573 148.435 53.2294 119.528 -5.22472e-06L-1.56487e-05 0C41.25 48.3608 65.935 111.327 65.935 179.487C65.935 247.322 41.25 309.639 0.649621 358Z"
                      fill="#00AAE7"
                    />
                  </svg>
                </div>
              </aside>
              <h1 className="section__title section__title--lg mt-4 mobile--only">
                Projects
              </h1>
              <aside className="col image--wrapper">
                <p className="card__title">{des}</p>
              </aside>
            </div>
          </div>
        </section>
        <ProjectsList />
      </main>
    </>
  );
};

export default Projects;
