import React from "react";
import { useTrail, a } from "react-spring";

const Trail = ({ open, children, ...props }) => {
  const items = React.Children.toArray(children);

  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? (window.innerWidth > 840 ? 72 : 42) : 0,
    from: { opacity: 0, x: 0, height: 0 },
    delay: window.innerWidth > 840 ? 900 : 300,
  });

  return (
    <div className="trails-main" {...props}>
      <h2
        className={
          "section__title " + (props.className ? props.className : " ")
        }
      >
        {window.innerWidth > 600
          ? trail.map(({ x, height, ...rest }, index) => (
              <a.div
                key={index}
                className="trails-text"
                style={{
                  ...rest,
                  transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
                }}
              >
                <a.div style={{ height }}>{items[index]}</a.div>
              </a.div>
            ))
          : trail.map((elem, index) => {
              return (
                <>
                  <span>{items[index]}</span>
                  <br />
                </>
              );
            })}
      </h2>
    </div>
  );
};

export default Trail;
