import Counter from "./Counter";
import RoadmapData from "./RoadmapData";
import testiReducer from "./testiReducer";
import teamReducer from "./teamReducer";

import BodyOverflow from "./BodyOverflow";
import Location from "./Location";
import projectAPI from "./projectAPI";
import singleAPI from "./singleAPI";
import EnqueryReducer from "./EnqueryReducer";
import ProjectInitiateReducers from "./ProjectInitiateReducers";

import { combineReducers } from "redux";

const allReducers = combineReducers({
  Counter,
  RoadmapData,
  BodyOverflow,
  Location,
  projectAPI,
  singleAPI,
  EnqueryReducer,
  ProjectInitiateReducers,
  testiReducer,
  teamReducer,
});

export default allReducers;
