import React from "react";

import Trail from "./Trail";
import { Link } from "react-router-dom";
import { ReactComponent as FireIcon } from "../svg/fire.svg";
import { ReactComponent as IceIcon } from "../svg/ice.svg";

const Footer = () => {
  return (
    // <VisibilitySensor
    //   onChange={visibility}
    //   scrollCheck={true}
    //   minTopValue={100}
    // >
    <footer id="footer" className={window.innerWidth < 600 ? " active " : " "}>
      <div className="section__rule vh">
        <div className="d-flex">
          <div className="d-flex">
            <aside className="footer--left aside--left">
              <ul className="nav flex-column social">
                <li className="nav-item" key="0">
                  <a
                    className="nav-link "
                    href="https://www.facebook.com/digitalghumti/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="active"
                    >
                      <path
                        d="M11.3802 22.7605C5.10778 22.7605 0 17.6527 0 11.3802C0 5.10778 5.10778 0 11.3802 0C17.6527 0 22.7605 5.10778 22.7605 11.3802C22.7605 17.6527 17.6527 22.7605 11.3802 22.7605ZM11.3802 1.90305C6.15827 1.90305 1.90305 6.15827 1.90305 11.3802C1.90305 16.6022 6.15827 20.8574 11.3802 20.8574C16.6022 20.8574 20.8574 16.6022 20.8574 11.3802C20.8574 6.15827 16.6022 1.90305 11.3802 1.90305Z"
                        fill="white"
                      />
                      <path
                        d="M10.2384 10.0099C9.71315 10.0099 9.28687 9.58366 9.28687 9.05842C9.28687 6.85849 11.0833 5.06201 13.2833 5.06201H13.9684C14.4936 5.06201 14.9199 5.48829 14.9199 6.01354C14.9199 6.53878 14.4936 6.96506 13.9684 6.96506H13.2833C12.1262 6.96506 11.1899 7.90136 11.1899 9.05842C11.1899 9.58366 10.7636 10.0099 10.2384 10.0099Z"
                        fill="white"
                      />
                      <path
                        d="M10.2384 22.6843C9.71315 22.6843 9.28687 22.258 9.28687 21.7327V9.02037C9.28687 8.49513 9.71315 8.06885 10.2384 8.06885C10.7636 8.06885 11.1899 8.49513 11.1899 9.02037V21.7327C11.1899 22.258 10.7636 22.6843 10.2384 22.6843Z"
                        fill="white"
                      />
                      <path
                        d="M13.9684 13.5876H7.72643C7.20119 13.5876 6.7749 13.1613 6.7749 12.6361C6.7749 12.1109 7.20119 11.6846 7.72643 11.6846H13.9684C14.4937 11.6846 14.92 12.1109 14.92 12.6361C14.92 13.1613 14.4937 13.5876 13.9684 13.5876Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.5 0C5.16154 0 0 5.16154 0 11.5C0 17.1154 4.05385 21.8077 9.38462 22.8077V13.7308H7.80769C7.27692 13.7308 6.84615 13.3 6.84615 12.7692C6.84615 12.2385 7.27692 11.8077 7.80769 11.8077H9.38462V9.11539C9.38462 9.1 9.38462 9.09231 9.38462 9.07692C9.42308 6.88462 11.2231 5.11538 13.4231 5.11538H14.1154C14.6462 5.11538 15.0769 5.54615 15.0769 6.07692C15.0769 6.60769 14.6462 7.03846 14.1154 7.03846H13.4231C12.2538 7.03846 11.3077 7.98462 11.3077 9.15385V11.8077H14.1154C14.6462 11.8077 15.0769 12.2385 15.0769 12.7692C15.0769 13.3 14.6462 13.7308 14.1154 13.7308H11.3077V23C11.3692 23 11.4385 23 11.5 23C17.8385 23 23 17.8385 23 11.5C23 5.16154 17.8385 0 11.5 0Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
                <li className="nav-item" key="1">
                  <a
                    className="nav-link "
                    href="https://www.instagram.com/digitalghumti/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="active"
                    >
                      <path
                        d="M11.3801 16.9751C8.29718 16.9751 5.78516 14.4631 5.78516 11.3801C5.78516 8.29718 8.29718 5.78516 11.3801 5.78516C14.4631 5.78516 16.9751 8.29718 16.9751 11.3801C16.9751 14.4631 14.4631 16.9751 11.3801 16.9751ZM11.3801 7.68821C9.34766 7.68821 7.68821 9.34766 7.68821 11.3801C7.68821 13.4126 9.34766 15.072 11.3801 15.072C13.4126 15.072 15.072 13.4126 15.072 11.3801C15.072 9.34766 13.4126 7.68821 11.3801 7.68821Z"
                        fill="white"
                      />
                      <path
                        d="M17.546 6.69845C16.8152 6.69845 16.2139 6.09709 16.2139 5.36631C16.2139 4.63554 16.8152 4.03418 17.546 4.03418C18.2768 4.03418 18.8781 4.63554 18.8781 5.36631C18.8781 6.09709 18.2768 6.69845 17.546 6.69845Z"
                        fill="white"
                      />
                      <path
                        d="M16.9219 22.7605H5.83856C2.6186 22.7605 0 20.1419 0 16.9219V5.83856C0 2.6186 2.6186 0 5.83856 0H16.9295C20.1419 0 22.7605 2.6186 22.7605 5.83856V16.9295C22.7605 20.1419 20.1419 22.7605 16.9219 22.7605ZM5.83856 1.90305C3.66908 1.90305 1.90305 3.66908 1.90305 5.83856V16.9295C1.90305 19.099 3.66908 20.865 5.83856 20.865H16.9295C19.099 20.865 20.865 19.099 20.865 16.9295V5.83856C20.865 3.66908 19.099 1.90305 16.9295 1.90305H5.83856Z"
                        fill="white"
                      />
                    </svg>

                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.059 0H5.88585C2.63981 0 0 2.64615 0 5.9V17.1077C0 20.3538 2.63981 23 5.88585 23H17.0667C20.3127 23 22.9525 20.3538 22.9525 17.1V5.9C22.9449 2.64615 20.305 0 17.059 0ZM11.4724 17.1538C8.36451 17.1538 5.83214 14.6154 5.83214 11.5C5.83214 8.38462 8.36451 5.84615 11.4724 5.84615C14.5803 5.84615 17.1127 8.38462 17.1127 11.5C17.1127 14.6154 14.5803 17.1538 11.4724 17.1538ZM17.6883 6.76923C16.9516 6.76923 16.3453 6.16154 16.3453 5.42308C16.3453 4.68462 16.9516 4.07692 17.6883 4.07692C18.4249 4.07692 19.0312 4.68462 19.0312 5.42308C19.0312 6.16154 18.4249 6.76923 17.6883 6.76923Z"
                        fill="white"
                      />
                      <path
                        d="M11.4726 15.2311C13.5281 15.2311 15.1944 13.5607 15.1944 11.5003C15.1944 9.43985 13.5281 7.76953 11.4726 7.76953C9.41705 7.76953 7.75073 9.43985 7.75073 11.5003C7.75073 13.5607 9.41705 15.2311 11.4726 15.2311Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
                <li className="nav-item" key="2">
                  <a
                    className="nav-link "
                    href="https://www.pinterest.com/digitalghumti2019/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="active"
                    >
                      <path
                        d="M11.3802 22.7605C5.10778 22.7605 0 17.6527 0 11.3802C0 5.10778 5.10778 0 11.3802 0C17.6527 0 22.7605 5.10778 22.7605 11.3802C22.7605 17.6527 17.6527 22.7605 11.3802 22.7605ZM11.3802 1.90305C6.15827 1.90305 1.90305 6.15827 1.90305 11.3802C1.90305 16.6022 6.15827 20.8574 11.3802 20.8574C16.6022 20.8574 20.8574 16.6022 20.8574 11.3802C20.8574 6.15827 16.6022 1.90305 11.3802 1.90305Z"
                        fill="white"
                      />
                      <path
                        d="M11.3803 17.8886C10.6952 17.8886 10.0177 17.7821 9.36302 17.5689C8.86062 17.4091 8.58658 16.8686 8.75405 16.3662C8.92152 15.8638 9.45437 15.5898 9.95677 15.7572C10.4135 15.9095 10.8931 15.9856 11.3803 15.9856C13.9227 15.9856 15.9856 13.9227 15.9856 11.3802C15.9856 8.83774 13.9227 6.77483 11.3803 6.77483C8.83778 6.77483 6.77488 8.83774 6.77488 11.3802C6.77488 12.3546 7.07936 13.2909 7.65028 14.0825C7.96238 14.5088 7.86342 15.1026 7.43714 15.4147C7.01085 15.7268 6.4171 15.6278 6.105 15.2015C5.29811 14.0902 4.87183 12.7656 4.87183 11.3878C4.87183 7.80248 7.79491 4.87939 11.3803 4.87939C14.9656 4.87939 17.8887 7.80248 17.8887 11.3878C17.8887 14.9732 14.9656 17.8886 11.3803 17.8886Z"
                        fill="white"
                      />
                      <path
                        d="M8.2594 22.3037C8.17566 22.3037 8.09193 22.2961 8.0082 22.2656C7.49818 22.1286 7.2013 21.6034 7.34593 21.0933L10.4669 9.82729C10.604 9.31727 11.1292 9.02039 11.6392 9.16503C12.1492 9.30205 12.4461 9.82729 12.3015 10.3373L9.18047 21.6034C9.05868 22.0296 8.67807 22.3037 8.2594 22.3037Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.5 0C5.16154 0 0 5.15809 0 11.4923C0 16.3122 2.98461 20.4479 7.2 22.1544L10.5692 9.93182C10.7077 9.41678 11.2385 9.11698 11.7538 9.26303C12.2692 9.4014 12.5692 9.93182 12.4231 10.4469L10.8615 16.1046C11.0692 16.1354 11.2846 16.1507 11.4923 16.1507C14.0615 16.1507 16.1462 14.0675 16.1462 11.5C16.1462 8.93249 14.0692 6.84158 11.5 6.84158C8.93077 6.84158 6.84615 8.9248 6.84615 11.4923C6.84615 12.4763 7.15385 13.4218 7.73077 14.2213C8.04615 14.6517 7.94616 15.2513 7.51539 15.5665C7.08462 15.8817 6.48462 15.7818 6.16923 15.3513C5.35385 14.2289 4.92308 12.8914 4.92308 11.5C4.92308 7.87935 7.87692 4.92747 11.5 4.92747C15.1231 4.92747 18.0769 7.87935 18.0769 11.5C18.0769 15.1207 15.1231 18.0725 11.5 18.0725C11.1154 18.0725 10.7308 18.0341 10.3538 17.9726L9.03846 22.7309C9.83077 22.9078 10.6538 23 11.5 23C17.8385 23 23 17.8419 23 11.5077C23 5.17346 17.8385 0 11.5 0Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
                <li className="nav-item" key="3">
                  <a
                    className="nav-link "
                    href="https://www.linkedin.com/company/digital-ghumti/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="active"
                    >
                      <path
                        d="M18.6956 22.7605H4.06492C1.81932 22.7605 0 20.9335 0 18.6956V4.06491C0 1.81932 1.82693 0 4.06492 0H18.6956C20.9412 0 22.7605 1.82693 22.7605 4.06491V18.6956C22.7605 20.9335 20.9336 22.7605 18.6956 22.7605ZM4.06492 1.90305C2.8698 1.90305 1.90305 2.8698 1.90305 4.06491V18.6956C1.90305 19.8907 2.8698 20.8574 4.06492 20.8574H18.6956C19.8907 20.8574 20.8574 19.8907 20.8574 18.6956V4.06491C20.8574 2.8698 19.8907 1.90305 18.6956 1.90305H4.06492Z"
                        fill="white"
                      />
                      <path
                        d="M5.8232 6.73673C5.33602 6.73673 4.94019 6.3409 4.94019 5.85372C4.94019 5.36654 5.33602 4.9707 5.8232 4.9707C6.31038 4.9707 6.70622 5.36654 6.70622 5.85372C6.70622 6.3409 6.31038 6.73673 5.8232 6.73673Z"
                        fill="white"
                      />
                      <path
                        d="M5.82335 18.5358C5.29811 18.5358 4.87183 18.1095 4.87183 17.5842V9.67516C4.87183 9.14992 5.29811 8.72363 5.82335 8.72363C6.34859 8.72363 6.77488 9.14992 6.77488 9.67516V17.5766C6.77488 18.1095 6.34859 18.5358 5.82335 18.5358Z"
                        fill="white"
                      />
                      <path
                        d="M10.0177 18.5358C9.49245 18.5358 9.06616 18.1095 9.06616 17.5842V9.67516C9.06616 9.14992 9.49245 8.72363 10.0177 8.72363C10.5429 8.72363 10.9692 9.14992 10.9692 9.67516V17.5766C10.9692 18.1095 10.5429 18.5358 10.0177 18.5358Z"
                        fill="white"
                      />
                      <path
                        d="M17.234 18.5359C16.7088 18.5359 16.2825 18.1096 16.2825 17.5844V13.9762C16.2825 12.5071 15.0874 11.3196 13.6259 11.3196C12.1643 11.3196 10.9692 12.5147 10.9692 13.9762V17.5844C10.9692 18.1096 10.5429 18.5359 10.0177 18.5359C9.49245 18.5359 9.06616 18.1096 9.06616 17.5844V13.9762C9.06616 11.4642 11.1138 9.4165 13.6259 9.4165C16.1379 9.4165 18.1856 11.4642 18.1856 13.9762V17.5844C18.1856 18.1096 17.7593 18.5359 17.234 18.5359Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.8923 0H4.10769C1.83846 0 0 1.84615 0 4.10769V18.8923C0 21.1615 1.84616 23 4.10769 23H18.8923C21.1615 23 23 21.1538 23 18.8923V4.10769C23 1.83846 21.1539 0 18.8923 0ZM5.88462 5.02308C6.37692 5.02308 6.77693 5.42308 6.77693 5.91538C6.77693 6.40769 6.37692 6.80769 5.88462 6.80769C5.39231 6.80769 4.99231 6.40769 4.99231 5.91538C4.99231 5.42308 5.39231 5.02308 5.88462 5.02308ZM6.84615 17.7692C6.84615 18.3 6.41538 18.7308 5.88462 18.7308C5.35385 18.7308 4.92308 18.3 4.92308 17.7692V9.77692C4.92308 9.24615 5.35385 8.81538 5.88462 8.81538C6.41538 8.81538 6.84615 9.24615 6.84615 9.77692V17.7692ZM18.3769 17.7692C18.3769 18.3 17.9462 18.7308 17.4154 18.7308C16.8846 18.7308 16.4538 18.3 16.4538 17.7692V14.1231C16.4538 12.6385 15.2462 11.4385 13.7692 11.4385C12.2923 11.4385 11.0846 12.6462 11.0846 14.1231V17.7692C11.0846 18.3 10.6539 18.7308 10.1231 18.7308C9.59232 18.7308 9.16155 18.3 9.16155 17.7692V14.1231V9.77692C9.16155 9.24615 9.59232 8.81538 10.1231 8.81538C10.6539 8.81538 11.0846 9.24615 11.0846 9.77692V10.3692C11.8385 9.82308 12.7692 9.5 13.7692 9.5C16.3077 9.5 18.3769 11.5692 18.3769 14.1077V17.7692Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </aside>

            <aside className="footer--right aside--right">
              <Trail open={true} className="white" key="footer">
                <span>What will you </span>
                <span>take ?</span>
              </Trail>
              <div className="button--group">
                <Link to="/contact/projectform">
                  <button
                    className="btn-slide"
                    onClick={() => window.scroll(0, 0)}
                  >
                    <FireIcon className="fire" />
                    <span className="unhidden">Hot</span>
                    <span className="hidden"> - Start a Project</span>
                  </button>
                </Link>
                <Link to="/contact/enqueryform">
                  <button
                    className="btn-slide"
                    // onClick={() => window.scroll(0, 0)}
                  >
                    <IceIcon className="fire" />
                    <span className="unhidden">Cold</span>
                    <span className="hidden"> - Say Hello !</span>
                  </button>
                </Link>
              </div>
            </aside>
          </div>
          <div className="footer--bottom">
            <p className="para copyright">
              &copy; 2021 Digital Ghumti Pvt.Ltd.
            </p>
            <ul className="nav flex-column social">
              <li className="nav-item" key="0">
                <a
                  className="nav-link "
                  href="https://www.facebook.com/digitalghumti/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="active"
                  >
                    <path
                      d="M11.3802 22.7605C5.10778 22.7605 0 17.6527 0 11.3802C0 5.10778 5.10778 0 11.3802 0C17.6527 0 22.7605 5.10778 22.7605 11.3802C22.7605 17.6527 17.6527 22.7605 11.3802 22.7605ZM11.3802 1.90305C6.15827 1.90305 1.90305 6.15827 1.90305 11.3802C1.90305 16.6022 6.15827 20.8574 11.3802 20.8574C16.6022 20.8574 20.8574 16.6022 20.8574 11.3802C20.8574 6.15827 16.6022 1.90305 11.3802 1.90305Z"
                      fill="white"
                    />
                    <path
                      d="M10.2384 10.0099C9.71315 10.0099 9.28687 9.58366 9.28687 9.05842C9.28687 6.85849 11.0833 5.06201 13.2833 5.06201H13.9684C14.4936 5.06201 14.9199 5.48829 14.9199 6.01354C14.9199 6.53878 14.4936 6.96506 13.9684 6.96506H13.2833C12.1262 6.96506 11.1899 7.90136 11.1899 9.05842C11.1899 9.58366 10.7636 10.0099 10.2384 10.0099Z"
                      fill="white"
                    />
                    <path
                      d="M10.2384 22.6843C9.71315 22.6843 9.28687 22.258 9.28687 21.7327V9.02037C9.28687 8.49513 9.71315 8.06885 10.2384 8.06885C10.7636 8.06885 11.1899 8.49513 11.1899 9.02037V21.7327C11.1899 22.258 10.7636 22.6843 10.2384 22.6843Z"
                      fill="white"
                    />
                    <path
                      d="M13.9684 13.5876H7.72643C7.20119 13.5876 6.7749 13.1613 6.7749 12.6361C6.7749 12.1109 7.20119 11.6846 7.72643 11.6846H13.9684C14.4937 11.6846 14.92 12.1109 14.92 12.6361C14.92 13.1613 14.4937 13.5876 13.9684 13.5876Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5 0C5.16154 0 0 5.16154 0 11.5C0 17.1154 4.05385 21.8077 9.38462 22.8077V13.7308H7.80769C7.27692 13.7308 6.84615 13.3 6.84615 12.7692C6.84615 12.2385 7.27692 11.8077 7.80769 11.8077H9.38462V9.11539C9.38462 9.1 9.38462 9.09231 9.38462 9.07692C9.42308 6.88462 11.2231 5.11538 13.4231 5.11538H14.1154C14.6462 5.11538 15.0769 5.54615 15.0769 6.07692C15.0769 6.60769 14.6462 7.03846 14.1154 7.03846H13.4231C12.2538 7.03846 11.3077 7.98462 11.3077 9.15385V11.8077H14.1154C14.6462 11.8077 15.0769 12.2385 15.0769 12.7692C15.0769 13.3 14.6462 13.7308 14.1154 13.7308H11.3077V23C11.3692 23 11.4385 23 11.5 23C17.8385 23 23 17.8385 23 11.5C23 5.16154 17.8385 0 11.5 0Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </li>
              <li className="nav-item" key="1">
                <a
                  className="nav-link "
                  href="https://www.instagram.com/digitalghumti/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="active"
                  >
                    <path
                      d="M11.3801 16.9751C8.29718 16.9751 5.78516 14.4631 5.78516 11.3801C5.78516 8.29718 8.29718 5.78516 11.3801 5.78516C14.4631 5.78516 16.9751 8.29718 16.9751 11.3801C16.9751 14.4631 14.4631 16.9751 11.3801 16.9751ZM11.3801 7.68821C9.34766 7.68821 7.68821 9.34766 7.68821 11.3801C7.68821 13.4126 9.34766 15.072 11.3801 15.072C13.4126 15.072 15.072 13.4126 15.072 11.3801C15.072 9.34766 13.4126 7.68821 11.3801 7.68821Z"
                      fill="white"
                    />
                    <path
                      d="M17.546 6.69845C16.8152 6.69845 16.2139 6.09709 16.2139 5.36631C16.2139 4.63554 16.8152 4.03418 17.546 4.03418C18.2768 4.03418 18.8781 4.63554 18.8781 5.36631C18.8781 6.09709 18.2768 6.69845 17.546 6.69845Z"
                      fill="white"
                    />
                    <path
                      d="M16.9219 22.7605H5.83856C2.6186 22.7605 0 20.1419 0 16.9219V5.83856C0 2.6186 2.6186 0 5.83856 0H16.9295C20.1419 0 22.7605 2.6186 22.7605 5.83856V16.9295C22.7605 20.1419 20.1419 22.7605 16.9219 22.7605ZM5.83856 1.90305C3.66908 1.90305 1.90305 3.66908 1.90305 5.83856V16.9295C1.90305 19.099 3.66908 20.865 5.83856 20.865H16.9295C19.099 20.865 20.865 19.099 20.865 16.9295V5.83856C20.865 3.66908 19.099 1.90305 16.9295 1.90305H5.83856Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.059 0H5.88585C2.63981 0 0 2.64615 0 5.9V17.1077C0 20.3538 2.63981 23 5.88585 23H17.0667C20.3127 23 22.9525 20.3538 22.9525 17.1V5.9C22.9449 2.64615 20.305 0 17.059 0ZM11.4724 17.1538C8.36451 17.1538 5.83214 14.6154 5.83214 11.5C5.83214 8.38462 8.36451 5.84615 11.4724 5.84615C14.5803 5.84615 17.1127 8.38462 17.1127 11.5C17.1127 14.6154 14.5803 17.1538 11.4724 17.1538ZM17.6883 6.76923C16.9516 6.76923 16.3453 6.16154 16.3453 5.42308C16.3453 4.68462 16.9516 4.07692 17.6883 4.07692C18.4249 4.07692 19.0312 4.68462 19.0312 5.42308C19.0312 6.16154 18.4249 6.76923 17.6883 6.76923Z"
                      fill="white"
                    />
                    <path
                      d="M11.4726 15.2311C13.5281 15.2311 15.1944 13.5607 15.1944 11.5003C15.1944 9.43985 13.5281 7.76953 11.4726 7.76953C9.41705 7.76953 7.75073 9.43985 7.75073 11.5003C7.75073 13.5607 9.41705 15.2311 11.4726 15.2311Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </li>
              <li className="nav-item" key="2">
                <a
                  className="nav-link "
                  href="https://www.pinterest.com/digitalghumti2019/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="active"
                  >
                    <path
                      d="M11.3802 22.7605C5.10778 22.7605 0 17.6527 0 11.3802C0 5.10778 5.10778 0 11.3802 0C17.6527 0 22.7605 5.10778 22.7605 11.3802C22.7605 17.6527 17.6527 22.7605 11.3802 22.7605ZM11.3802 1.90305C6.15827 1.90305 1.90305 6.15827 1.90305 11.3802C1.90305 16.6022 6.15827 20.8574 11.3802 20.8574C16.6022 20.8574 20.8574 16.6022 20.8574 11.3802C20.8574 6.15827 16.6022 1.90305 11.3802 1.90305Z"
                      fill="white"
                    />
                    <path
                      d="M11.3803 17.8886C10.6952 17.8886 10.0177 17.7821 9.36302 17.5689C8.86062 17.4091 8.58658 16.8686 8.75405 16.3662C8.92152 15.8638 9.45437 15.5898 9.95677 15.7572C10.4135 15.9095 10.8931 15.9856 11.3803 15.9856C13.9227 15.9856 15.9856 13.9227 15.9856 11.3802C15.9856 8.83774 13.9227 6.77483 11.3803 6.77483C8.83778 6.77483 6.77488 8.83774 6.77488 11.3802C6.77488 12.3546 7.07936 13.2909 7.65028 14.0825C7.96238 14.5088 7.86342 15.1026 7.43714 15.4147C7.01085 15.7268 6.4171 15.6278 6.105 15.2015C5.29811 14.0902 4.87183 12.7656 4.87183 11.3878C4.87183 7.80248 7.79491 4.87939 11.3803 4.87939C14.9656 4.87939 17.8887 7.80248 17.8887 11.3878C17.8887 14.9732 14.9656 17.8886 11.3803 17.8886Z"
                      fill="white"
                    />
                    <path
                      d="M8.2594 22.3037C8.17566 22.3037 8.09193 22.2961 8.0082 22.2656C7.49818 22.1286 7.2013 21.6034 7.34593 21.0933L10.4669 9.82729C10.604 9.31727 11.1292 9.02039 11.6392 9.16503C12.1492 9.30205 12.4461 9.82729 12.3015 10.3373L9.18047 21.6034C9.05868 22.0296 8.67807 22.3037 8.2594 22.3037Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5 0C5.16154 0 0 5.15809 0 11.4923C0 16.3122 2.98461 20.4479 7.2 22.1544L10.5692 9.93182C10.7077 9.41678 11.2385 9.11698 11.7538 9.26303C12.2692 9.4014 12.5692 9.93182 12.4231 10.4469L10.8615 16.1046C11.0692 16.1354 11.2846 16.1507 11.4923 16.1507C14.0615 16.1507 16.1462 14.0675 16.1462 11.5C16.1462 8.93249 14.0692 6.84158 11.5 6.84158C8.93077 6.84158 6.84615 8.9248 6.84615 11.4923C6.84615 12.4763 7.15385 13.4218 7.73077 14.2213C8.04615 14.6517 7.94616 15.2513 7.51539 15.5665C7.08462 15.8817 6.48462 15.7818 6.16923 15.3513C5.35385 14.2289 4.92308 12.8914 4.92308 11.5C4.92308 7.87935 7.87692 4.92747 11.5 4.92747C15.1231 4.92747 18.0769 7.87935 18.0769 11.5C18.0769 15.1207 15.1231 18.0725 11.5 18.0725C11.1154 18.0725 10.7308 18.0341 10.3538 17.9726L9.03846 22.7309C9.83077 22.9078 10.6538 23 11.5 23C17.8385 23 23 17.8419 23 11.5077C23 5.17346 17.8385 0 11.5 0Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </li>
              <li className="nav-item" key="3">
                <a
                  className="nav-link "
                  href="https://www.linkedin.com/company/digital-ghumti/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="active"
                  >
                    <path
                      d="M18.6956 22.7605H4.06492C1.81932 22.7605 0 20.9335 0 18.6956V4.06491C0 1.81932 1.82693 0 4.06492 0H18.6956C20.9412 0 22.7605 1.82693 22.7605 4.06491V18.6956C22.7605 20.9335 20.9336 22.7605 18.6956 22.7605ZM4.06492 1.90305C2.8698 1.90305 1.90305 2.8698 1.90305 4.06491V18.6956C1.90305 19.8907 2.8698 20.8574 4.06492 20.8574H18.6956C19.8907 20.8574 20.8574 19.8907 20.8574 18.6956V4.06491C20.8574 2.8698 19.8907 1.90305 18.6956 1.90305H4.06492Z"
                      fill="white"
                    />
                    <path
                      d="M5.8232 6.73673C5.33602 6.73673 4.94019 6.3409 4.94019 5.85372C4.94019 5.36654 5.33602 4.9707 5.8232 4.9707C6.31038 4.9707 6.70622 5.36654 6.70622 5.85372C6.70622 6.3409 6.31038 6.73673 5.8232 6.73673Z"
                      fill="white"
                    />
                    <path
                      d="M5.82335 18.5358C5.29811 18.5358 4.87183 18.1095 4.87183 17.5842V9.67516C4.87183 9.14992 5.29811 8.72363 5.82335 8.72363C6.34859 8.72363 6.77488 9.14992 6.77488 9.67516V17.5766C6.77488 18.1095 6.34859 18.5358 5.82335 18.5358Z"
                      fill="white"
                    />
                    <path
                      d="M10.0177 18.5358C9.49245 18.5358 9.06616 18.1095 9.06616 17.5842V9.67516C9.06616 9.14992 9.49245 8.72363 10.0177 8.72363C10.5429 8.72363 10.9692 9.14992 10.9692 9.67516V17.5766C10.9692 18.1095 10.5429 18.5358 10.0177 18.5358Z"
                      fill="white"
                    />
                    <path
                      d="M17.234 18.5359C16.7088 18.5359 16.2825 18.1096 16.2825 17.5844V13.9762C16.2825 12.5071 15.0874 11.3196 13.6259 11.3196C12.1643 11.3196 10.9692 12.5147 10.9692 13.9762V17.5844C10.9692 18.1096 10.5429 18.5359 10.0177 18.5359C9.49245 18.5359 9.06616 18.1096 9.06616 17.5844V13.9762C9.06616 11.4642 11.1138 9.4165 13.6259 9.4165C16.1379 9.4165 18.1856 11.4642 18.1856 13.9762V17.5844C18.1856 18.1096 17.7593 18.5359 17.234 18.5359Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.8923 0H4.10769C1.83846 0 0 1.84615 0 4.10769V18.8923C0 21.1615 1.84616 23 4.10769 23H18.8923C21.1615 23 23 21.1538 23 18.8923V4.10769C23 1.83846 21.1539 0 18.8923 0ZM5.88462 5.02308C6.37692 5.02308 6.77693 5.42308 6.77693 5.91538C6.77693 6.40769 6.37692 6.80769 5.88462 6.80769C5.39231 6.80769 4.99231 6.40769 4.99231 5.91538C4.99231 5.42308 5.39231 5.02308 5.88462 5.02308ZM6.84615 17.7692C6.84615 18.3 6.41538 18.7308 5.88462 18.7308C5.35385 18.7308 4.92308 18.3 4.92308 17.7692V9.77692C4.92308 9.24615 5.35385 8.81538 5.88462 8.81538C6.41538 8.81538 6.84615 9.24615 6.84615 9.77692V17.7692ZM18.3769 17.7692C18.3769 18.3 17.9462 18.7308 17.4154 18.7308C16.8846 18.7308 16.4538 18.3 16.4538 17.7692V14.1231C16.4538 12.6385 15.2462 11.4385 13.7692 11.4385C12.2923 11.4385 11.0846 12.6462 11.0846 14.1231V17.7692C11.0846 18.3 10.6539 18.7308 10.1231 18.7308C9.59232 18.7308 9.16155 18.3 9.16155 17.7692V14.1231V9.77692C9.16155 9.24615 9.59232 8.81538 10.1231 8.81538C10.6539 8.81538 11.0846 9.24615 11.0846 9.77692V10.3692C11.8385 9.82308 12.7692 9.5 13.7692 9.5C16.3077 9.5 18.3769 11.5692 18.3769 14.1077V17.7692Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </li>
            </ul>

            <address className="emailus">
              <p className="para ">Or email us at</p>

              <a
                rel="dns-prefetch"
                href="mailto:info@digitalghumti.com"
                className="para "
              >
                <b>info@digitalghumti.com</b>
              </a>
            </address>
            <a
              href="https://dribbble.com/samirjay49"
              target="_blank"
              className="para uiux"
              rel="noreferrer"
            >
              UI/UX <span className="text--secondary">Samirjay Art</span>
            </a>
            {/* <a rel="dns-prefetch" href="#" className="para backtotop">Back to Top</a> */}
          </div>
        </div>
      </div>
      <div className="footer--bg"></div>
    </footer>
    // </VisibilitySensor>
  );
};

export default Footer;
